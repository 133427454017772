<template>
  <div class="c-main">
    <h2>{{ $t('session.add') }}</h2>
    <session @submit="update($event)"/>
  </div>
</template>


<script>
export default {
  name: "session-add",
  components: {
    session: require('./session.vue').default,
  },
  methods: {
    update: function (session) {
      session.eventDisciplineId = this.$route.params.disciplineId;
      this.$notify.saveAsync(
        this.$t('session'),
        this.$store.dispatch('session.add', session),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.sessions'});
        }
      );
    },
  },
};
</script>

<style>
</style>
