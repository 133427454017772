<template>
  <grid :config="$options.searchConfig" :data="gridData">
    <template v-slot:gridAdd>

      <dropDown :label="$t('participation.add')" v-if="hasIndividual() && hasGroups()">
        <template slot="content">
          <router-link exact :to="{ name: 'admin.event.discipline.participants.add'}" v-if="hasIndividual()"
          class="c-button c-dropdown__button c-button--secondary c-button--icon" tag="button">
            <svg role="img" class="c-button__icon">
              <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
            </svg>
            <span class="c-button__label">{{$t("participation.addIndiv")}}</span>
          </router-link>

          <router-link exact :to="{ name: 'admin.event.discipline.participants.addGroup'}" v-if="hasGroups()"
          class="c-button c-dropdown__button c-button--secondary c-button--icon" tag="button">
            <svg role="img" class="c-button__icon">
              <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
            </svg>
            <span class="c-button__label">{{$t("participation.addGroup")}}</span>
          </router-link>
        </template>
      </dropDown>

      <router-link v-else exact :to="hasGroups() ? { name: 'admin.event.discipline.participants.addGroup'} : { name: 'admin.event.discipline.participants.add'}"
      class="c-button c-button--secondary c-button--large c-button--icon">
        <svg role="img" class="c-button__icon">
          <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
        </svg>
        <span class="c-button__label" v-if="hasIndividual()">{{$t("participation.addIndiv")}}</span>
        <span class="c-button__label" v-if="hasGroups()">{{$t("participation.addGroup")}}</span>
      </router-link>
    </template>
    <template v-slot:gridHeader>
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-1-of-8-at-medium">
          {{$t('bib')}}
        </div>
        <div class="o-layout__item u-2-of-8-at-medium">
          {{$t('name')}}
        </div>
        <div class="o-layout__item u-3-of-8-at-medium">
          {{$t('club')}}
        </div>
        <div class="o-layout__item u-2-of-8-at-medium">
          {{$t('category')}}
        </div>
      </div>
    </template>
    <template v-slot:gridData="{element}">
      <div class="o-layout__item u-1-of-8-at-medium c-list__main-cell">
        {{ element.bib }}
      </div>
      <div class="o-layout__item u-2-of-8-at-medium c-list__main-cell">
        <router-link class="c-list__link" :to="{ name: 'admin.event.discipline.participants.view',
          params: { participationId: element.id}}">
          <span class="name" v-for="(name, n_i) in element.names" :key="name"><br v-if="n_i > 0"/>{{name}}</span>
        </router-link>
      </div>
      <div class="o-layout__item u-3-of-8-at-medium">
        {{ element.club }}
      </div>
      <div class="o-layout__item u-2-of-8-at-medium">
        {{ element.category }}
      </div>
    </template>
  </grid>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import map from 'lodash/map';
  import sortBy from 'lodash/sortBy';

  import participantLib from 'client/lib/participant.js';

  export default {
    name: 'participationsList',
    components: {
      grid: require('client/components/dataGrid.vue').default,
      dropDown: require('client/components/dropDown.vue').default,
    },
    searchConfig: {
      searchFields: ['bib', 'names', 'club', 'category'],
      sortFields: ['bib', 'club', 'category'],
      defaultSortField: 'bib',
    },
    data: function () {
      return {
        gridData: [],
      };
    },
    computed: {
      loaded: function () {
        return this.$store.state.participations.loaded;

      },
      participations: function () {
        return filter(this.$store.state.participations.items, item => {
          return item.participantType !== 'team';
        });
      },
      clubs: function () {
        return this.$store.state.clubs.items;
      },
      categories: function () {
        return this.$store.state.categories.items;
      },
      discipline: function () {
        return this.$store.state.eventDiscipline.discipline;
      }
    },
    watch: {
      participations: {
        immediate: true,
        handler: function() {
          this.updateGridData();
        },
      }
    },
    methods: {
      getParticipantNames: function (participation) {
        return participantLib.getParticipantNames(participation);
      },
      getClub: function (participation) {
        const participant = participantLib.getParticipant(participation);
        if (! participant) {
          console.log('no participant', participation);
          return null;
        }
        return participantLib.getClub(participant);
      },
      getCategory: function (categoryId) {
        return find(this.categories, function (item) {
          return item.id === categoryId;
        });
      },
      hasGroups: function () {
        return this.discipline.group;
      },
      hasIndividual: function () {
        return this.discipline.individual;
      },
      updateGridData: function () {
        const parts = sortBy(this.participations, 'bib');
        this.gridData = map(parts, part => {
          const club = this.getClub(part);
          return {
            id: part.id,
            bib: part.bib,
            club: club ? club.name: '',
            category: this.getCategory(part.categoryId).name,
            names: this.getParticipantNames(part),
          };
        });
      },
    }
  };
</script>

<style scoped>
</style>
