<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('club.add')}}</h2>

    <club @submit="update($event)" />
  </div>
</template>


<script>
	export default {
		name: "club-add",
    components: {
      club: require('./club.vue').default,
    },
		methods: {
			update: function(club) {
        this.$notify.saveAsync(
            this.$t('club'),
            this.$store.dispatch('club.add', {data: club}),
            (resp) => {
              this.$navigation.goto({name: 'admin.event.clubs.view', params: {clubId: resp.data.id}});
            }
        );
			}
		},
	};
</script>

<style>
</style>
