<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('round.competition_rounds')}}</h2>
    <rounds v-model="rounds"/>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()">
          {{$t('save')}}
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import sortBy from 'lodash/sortBy';

import rounds from 'client/lib/rounds'
import map from "lodash/map";
import filter from "lodash/filter";
import config from "@/config";

export default {
  name: "eventDisciplineRounds",
  components: {
    rounds: require('client/components/models/rounds.vue').default,
  },
  data: function() {
    return {
      rounds: [],
    }
  },
  created: function() {
    this.rounds = cloneDeep(sortBy(rounds.getRounds(), 'index'))
  },
  computed: {
    discipline: function () {
      const ed = rounds.getEventDiscipline()
      return this.$store.state.disciplines.items.find(i => i.id === ed.disciplineId)
    },
  },
  methods: {
    other: function (index) {
      return map(filter(this.rounds, r => r.index > index), r => ({
        index: r.index,
        name: r.name
      }))
    },
    submit: function() {
      const oldRounds = rounds.getRounds()
      if (oldRounds.length > this.rounds.length) {
        this.$modal.show({
          title: this.$t('delete.confirm.title'),
          message: this.$t('delete.rounds.text'),
          onConfirm: () => {
            this.save()
          }
        })

      }
      else {
        this.save()
      }
    },
    checkDelete: function(ed) {
      return new Promise((resolve, reject) => {
        if (ed.rounds.length > this.rounds) {
          // TODO: check for existing sessions with removed rounds
          this.$modal.show({
            title: this.$t('round.remove.title'),
            message: this.$t('round.remove.message'),
            onConfirm: () => {
              resolve()
            },
            onCancel: () => {
              reject()
            }
          })
        }
        else {
          resolve()
        }
      })
    },
    save: function() {
      const ed = rounds.getEventDiscipline()

      this.checkDelete(ed).then(() => {
        this.$notify.saveAsync(
          this.$t('rounds.edit'),
          this.$http.post(config.root + '/eventDisciplines/' + ed.id + '/updateRounds', this.rounds),
        )
      }, () => {})
    }
  }
};
</script>
