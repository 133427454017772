<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-large">
      <sceTextField v-model="name" name="title" label="title"
                    v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('title')}"/>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="shortName" name="shortName" label="event.title_short"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="label" name="label" label="event.label"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="copyright" name="copyright" label="copyright"/>
        </div>
      </div>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="externalId" name="externalId" label="externalId"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="clubSymbols" name="clubSymbols" label="clubSymbols"
                     :options="$options.clubSymbolsOptions" :config="{ emptyLabel: 'none'}" />
        </div>
      </div>
      <div class="o-layout">
        <label class="c-input__label o-layout__item">{{ $t('discipline.logo') }}</label>
      </div>
      <image-input v-model="logoUrl"/>

      <div class="o-layout u-margin-bottom-medium" v-if="id">
        <div class="o-layout__item">
          <h3 class="c-title c-title--page-section">{{ $t('eventDiscipline.competitionSettings') }}</h3>
        </div>
        <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.group" >
          <sceSelect v-model="groupDisplay" name="groupDisplay" label="groupDisplay"
                     :options="$options.groupDisplay" :config="{}"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.team" >
          <sceSelect v-model="teamDisplay" name="teamDisplay" label="teamDisplay"
                     :options="$options.teamDisplay" :config="{}"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="finalsReports" name="finalsReports" label="discipline.finalsReports"
                     :options="$options.finalsReports" :config="{}"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.config.useJoker">
          <sceCheckBox v-model="useJokers" name="useJokers" label="useJokers"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceCheckBox v-model="judgesHideScores" name="judgesHideScores" label="judgesHideScores"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceCheckBox v-model="rankingAddGuests" name="rankingAddGuests" label="settings.rankingAddGuests"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small" v-if="discipline.events">
          <sceCheckBox v-model="addEventRankings" name="addEventRankings" label="settings.addEventRankings"/>
        </div>
      </div>

      <div class="o-layout u-margin-bottom-medium" v-if="id">
        <div class="o-layout__item">
          <h3 class="c-title c-title--page-section">{{ $t('eventDiscipline.publication') }}</h3>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="liveScoreMethod" name="liveScoreMethod" label="eventDiscipline.publicationMethod"
                     :options="$options.liveScoreOptions"/>
        </div>

        <div class="o-layout__item u-1-of-2-at-small u-padding-medium"
             v-if="eventDiscipline.liveScoreMethod && eventDiscipline.liveScoreMethod !== 'none'">
          <sceButton class="c-button--primary c-button--large" v-on:click="publishLive('live')"
                     label="live-score.publish-bulk"/>
        </div>
        <div v-if="eventDiscipline.liveScoreMethod === 'publicAPI'" class="o-layout__item u-1-of-1">
          <sceSelect v-model="liveScoreSettings.schedule" :options="$options.liveScoreSchedule"
                     label="liveScore.schedule" :config="{}" />

          <div class="c-input--extra-info">
            {{ $t('publicationMethod.ScoreExpress.desc') }}
          </div>
        </div>
        <div v-if="eventDiscipline.liveScoreMethod === 'ScoreExpress'" class="o-layout__item u-1-of-1">
          <sceTextField v-model="liveScoreSettings.liveUrl" name="liveUrl" label="liveUrl" />

          <div class="c-input--extra-info">
            {{ $t('publicationMethod.ScoreExpress.desc') }}
          </div>
        </div>
        <div v-if="publicURL" class="o-layout__item u-1-of-1 u-margin-bottom-medium">
          {{ $t('liveScore.visitUrl') }}:
          <a target="_blank" :href="publicURL">
             {{publicURL}}
          </a>
        </div>

        <div v-for="(item,i) of federationExport" :key="'export-' + i" class="o-layout__item u-1-of-1">
          <div class="o-layout">
            <div class="o-layout__item u-1-of-2">
              <p><strong>{{ item.label }}</strong></p>
            </div>
            <div class="o-layout__item u-1-of-2">
              <sceButton class="c-button--primary c-button--small" label="execute"
                         v-on:click="publishLive(item.type, item.method)" />
            </div>
          </div>
        </div>
      </div>

      <!-- ACRO COMPANION -->
      <div class="o-layout" v-if="id">
        <div class="o-layout__item">
          <h3 class="c-title c-title--page-section">{{ $t('ac.integration') }}</h3>
        </div>
        <div class="o-layout__item">
          <sceCheckBox v-model="acroCompanion.enabled" name="acEnabled" label="ac.enabled"/>
        </div>
        <div class="o-layout__item" v-if="acroCompanion.enabled">
          <sceCheckBox v-model="acroCompanion.syncSessions" name="acSyncSessions" label="acroCompanion.syncSessions"/>
          <sceCheckBox v-model="acroCompanion.syncScores" name="acESyncScores" label="acroCompanion.syncScores"/>
          <sceCheckBox v-model="acroCompanion.syncDisplay" name="acPushDisplay" label="acroCompanion.syncDisplay"/>
        </div>
      </div>
    </form>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete"/>
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save"/>
      </div>
    </footer>
  </div>
</template>

<script>
import config from '@/config';

import panelHandler from '@/lib/panelHandler'
import options from '@/lib/options'
import userLib from '@/lib/user'

import clone from 'lodash/clone'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import map from 'lodash/map'

export default {
  name: "eventDiscipline",
  components: {
    'image-input': require('client/components/imageInput.vue').default,
  },
  props: ['id', 'eventId'],
  ...options.eventDiscipline,
  data: function () {
    return {
      name: '',
      shortName: undefined,
      label: undefined,
      copyright: undefined,
      externalId: undefined,
      logoUrl: '',
      disciplineId: '',
      liveScoreMethod: 'none',
      liveScoreSettings: {},
      liveUrl: undefined,
      publicURL: null,
      clubSymbols: 'none',
      acroCompanion: {
        enabled: false,
        syncSessions: false,
        syncScores: false,
        syncDisplay: false,
      },
      groupDisplay: null,
      teamDisplay: 'team',
      finalsReports: 'regular',
      judgesHideScores: false,
      rankingAddGuests: false,
      addEventRankings: false,
      useJokers: false,
    }
  },
  computed: {
    eventDiscipline: function () {
      return find(this.$store.state.eventDisciplines.items, (item) => item.id === this.id)
    },
    discipline: function () {
      return this.$store.state.disciplines.items.find(i => i.id === this.eventDiscipline.disciplineId)
    },
    federationExport: function() {
      const event = this.$store.state.events.items.find(e => e.id === this.eventId)
      const user = userLib.getEventUser(event)
      let federationExport = user.configuration?.federationExport
      if (! federationExport) {
        federationExport = []
      }
      return federationExport
    }
  },
  mounted: function () {
    this.setDiscipline();
  },
  watch: {
    eventDiscipline: function () {
      this.setDiscipline();
    }
  },
  methods: {
    setDiscipline: function () {
      this.publicURL = null

      if (this.eventDiscipline) {
        this.name = this.eventDiscipline.name;
        this.shortName = this.eventDiscipline.shortName;
        this.label = this.eventDiscipline.label;
        this.copyright = this.eventDiscipline.copyright;
        this.externalId = this.eventDiscipline.accessId;
        this.logoUrl = this.eventDiscipline.logoUrl;
        this.disciplineId = this.eventDiscipline.disciplineId;
        this.liveScoreMethod = this.eventDiscipline.liveScoreMethod ? this.eventDiscipline.liveScoreMethod : 'none'
        this.liveScoreSettings = this.eventDiscipline.liveScoreSettings || {}
        this.acEnabled = this.eventDiscipline.acEnabled;
        this.clubSymbols = this.eventDiscipline.clubSymbols ? this.eventDiscipline.clubSymbols : 'none'
        this.judgesHideScores = this.eventDiscipline.judgesHideScores
        this.rankingAddGuests = this.eventDiscipline.rankingAddGuests
        this.groupDisplay = this.eventDiscipline.groupDisplay ? this.eventDiscipline.groupDisplay
          : this.discipline.labels.groupDisplay ? this.discipline.labels.groupDisplay : 'members'
        this.teamDisplay = this.eventDiscipline.teamDisplay
        this.finalsReports = this.eventDiscipline.finalsReports ? this.eventDiscipline.finalsReports : 'regular'
        this.useJokers = this.eventDiscipline.useJokers
        this.addEventRankings = this.eventDiscipline.addEventRankings
        this.acroCompanion = clone(this.eventDiscipline.acSettings || {})

        switch (this.eventDiscipline.liveScoreMethod) {
          case 'publicAPI': {
            const event = this.$store.state.events.current
            const user = userLib.getEventUser(event)
            this.publicURL = 'https://public.scoreexpress.be/competitions/' + user.id + '/' + this.eventDiscipline.id
            break
          }
          case 'ScoreExprsss':
            this.publicURL = 'https://results.scoreexpress.be/#/competition/' + this.eventDiscipline.accessId
            break
        }
      }
    },
    submit: function () {
      this.$emit('submit', {
        disciplineId: this.disciplineId,
        name: this.name,
        shortName: this.shortName,
        label: this.label,
        copyright: this.copyright,
        accessId: this.externalId,
        logoUrl: this.logoUrl,
        liveScoreMethod: this.liveScoreMethod,
        liveScoreSettings: this.liveScoreSettings,
        judgesHideScores: this.judgesHideScores,
        rankingAddGuests: this.rankingAddGuests,
        useJokers: this.useJokers,
        acEnabled: this.acEnabled,
        eventId: this.eventId,
        clubSymbols: this.clubSymbols,
        groupDisplay: this.groupDisplay,
        teamDisplay: this.teamDisplay,
        finalsReports: this.finalsReports,
        addEventRankings: this.addEventRankings,
        acSettings: this.acroCompanion,
        id: this.id,
      })
    },
    remove: function () {
      this.$modal.show({
        title: this.$t('delete.confirm.title'),
        message: this.$t('delete.confirm.text', {item: this.name}),
        onConfirm: () => {
          this.$emit('remove', this.id);
        }
      })
    },
    retrievePanels: function(sessionIds) {
      return this.$http.get(
        config.root + '/eventDisciplines/' + this.eventDiscipline.id + '/selectPanels',
        {params: {sessions: sessionIds}}
      );
    },
    sendJudges: function() {
      return new Promise((resolve, reject) => {
        const sessions = this.$store.state.sessions.items.filter(s => s.accessId)
        const rogueSessions = this.$store.state.sessions.items.filter(s => ! s.accessId)

        if (rogueSessions.length) {
          const names = rogueSessions.map(s => s.name).join(', ')
          this.$notify.warning('Sessies zonder ID: ' + names)
        }

        this.retrievePanels(map(sessions, session => session.id)).then(result => {
          const panels = result.body.result;
          const data = filter(map(sessions, session => {
            const sessionPanels = filter(panels, item => item.sessionId === session.id)
            let judges = []
            forEach(sessionPanels, panel => {
              const panelData = panelHandler.exportPanel(panel)
              const panelJudges = map(panelData.positions, position => {
                return {
                  JudgeId: position.judgeId,
                  firstName: position.judgeFirstName,
                  lastName: position.judgeLastName,
                  clubId: position.clubId,
                  panel: panelData.panel,
                  task: position.type,
                }
              });
              judges = concat(judges, panelJudges)
            })
            return {
              competitionId: this.eventDiscipline.accessId,
              sessionId: session.accessId,
              judges: judges,
            }
          }), item => item.judges.length)

          // TODO: add feedback message
          this.$http.post(config.root + '/exportJudges', data).then(result => {
            console.log(result)
            if (result.body.success) {
              resolve(result.body.message)
            } else {
              reject(result.body.message)
            }
          })
        })
      })
    },
    publishJudges: function() {
      this.$notify.notifyAsync(
        this.sendJudges(),
        this.$t('eventDiscipline.sendJudges.processing'),
        resp => this.$t('eventDiscipline.sendJudges.success') + '<br/>' + resp,
        err => this.$t('eventDiscipline.sendJudges.fail') + '<br/>' + err)
    },
    publishLive: function(type, method) {
      if (type === 'judges') {
        this.publishJudges()
      } else {
        // TODO: add feedback message
        this.$http.post(config.root + '/eventDisciplines/' + this.eventDiscipline.id + '/publishEventDiscipline',
          {type, method})
      }
    },
  }
};
</script>

<style>
</style>
