<template>
  <div class="exercise u-padding-bottom-huge">
    <exerciseHeader withCancel="true" @cancel="cancel()" @close="close()" />
    <exerciseEdit mode="admin" @close="close()" />
  </div>
</template>

<script>
  import store from 'client/store';

  export default {
    name: "exercise-dashboard",
    components: {
      'exerciseHeader': require('client/components/models/exerciseHeader.vue').default,
      'exerciseEdit': require('client/components/models/exercise-edit.vue').default,
    },
    methods: {
      cancel: function() {
        store.dispatch("exercise.cancel", { nopanel: true}).then(() => {
          this.close();
        });
      },
      close: function() {
        console.log('close exercise');
        this.$navigation.goto({name: 'admin.event.discipline.dashboard.block',
          params: {panelId: this.$route.params.panelId, blockId: this.$route.params.blockId,
            rotation: this.$route.params.rotation}});
      }
    }
  };
</script>

<style scoped>
</style>
