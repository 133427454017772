<template>
  <div class="c-main">
		<h2 class="c-title c-title--page">{{$t('eventDiscipline.edit')}}</h2>

		<event-discipline :id="$route.params.disciplineId" :eventId="$route.params.eventId" @submit="update($event)" @remove="remove($event)"></event-discipline>
  </div>
</template>

<script>
	import config from 'client/config';

	export default {
		name:'discipline-view',
    components: {
      "event-discipline": require('./eventDiscipline.vue').default,
    },
		methods: {
			update:function(event) {
        this.$notify.saveAsync(
            this.$t('eventDiscipline.edit'),
            this.$http.patch(config.root + '/eventDisciplines/' + event.id, event),
        );
			},
			remove:function(id) {
        this.$notify.removeAsync(
            this.$t('eventDiscipline'),
            this.$http.delete(config.root + '/eventDisciplines/' + id),
            () => {
              this.$navigation.goto({name: 'admin.event.view'});
            }
        );
			}
		},
	};
</script>

<style>
</style>
