<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{club.name}}</h2>

    <div class="c-tabs c-main">
      <div class="c-tab__tab-bar">
        <router-link v-for="tab in $options.tabs" :key="tab.name"
           class="c-button c-button--large c-button--ghost c-tabs__tab-link" :to="{ name: tab.name}" :exact="tab.exact">
          {{$t(tab.label)}}
        </router-link>
      </div>
      <div class="c-tab__panel c-main u-margin-top-medium">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import find from 'lodash/find';

  export default {
    name: "club-wrapper",
    tabs: [{
      name: 'admin.event.clubs.view',
      label: 'club.data',
      exact: true,
    },{
      name: 'admin.event.clubs.members',
      label: 'members',
      exact: false,
    },{
      name: 'admin.event.clubs.groups',
      label: 'groups',
      exact: false,
    },{
      name: 'admin.event.clubs.teams',
      label: 'teams',
      exact: false,
    }],
    computed: {
      club: function () {
        return find(this.$store.state.clubs.items, (item) => {
            return item.id === this.$route.params.clubId;
        });
      }
    }
  };
</script>

<style>
</style>
