<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('clubs') }}</h2>
    <grid :config="$options.config" :data="data" class="c-main--clubs-datagrid">
      <template v-slot:gridAdd>
        <sceRouteButton :route="{ name: 'admin.event.clubs.add'}" label="club.add"
                        class="c-button--secondary c-button--large" icon="svg-symbols.svg#plus-icon"/>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item u-3-of-5-at-medium">
            {{ $t('name') }}
          </div>
          <div class="o-layout__item u-1-of-5-at-medium">
            {{ $t('country') }}
          </div>
          <div class="o-layout__item u-1-of-5-at-medium">
            {{ $t('region') }}
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item u-3-of-5-at-medium c-list__main-cell">
          <router-link class="c-list__link"
                       :to="{ name: 'admin.event.clubs.view', params: { clubId: element.id}}">
            {{ element.name }}
          </router-link>
        </div>
        <div class="o-layout__item u-1-of-5-at-medium">
          {{ element.country }}
        </div>
        <div class="o-layout__item u-1-of-5-at-medium">
          {{ element.region }}
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

export default {
  name: 'clubs-list',
  components: {
    grid: require('client/components/dataGrid.vue').default,
  },
  config: {
    searchFields: ['name', 'country', 'region'],
    sortFields: ['name', 'country', 'region'],
    defaultSortField: 'name',
  },
  data: () => ({
    data: [],
  }),
  computed: {
    clubs: function () {
      return sortBy(this.$store.state.clubs.items, 'name');
    },
    countries: function () {
      return this.$store.state.events.countries;
    }
  },
  watch: {
    clubs: {
      immediate: true,
      handler: function() {
        this.prepareData()
      }
    }
  },
  methods: {
    prepareData: function() {
      console.log('prepare data')
      this.data = this.clubs.map(club => ({
        id: club.id,
        name: club.name,
        country: club.countryId ? this.$t(club.countryId) : '-',
        region: club.regionId ? this.getRegion(club.countryId, club.regionId).name : '-',
      }))
    },
    getCountry: function (countryId) {
      return find(this.countries, item => {
        return item.id === countryId;
      });
    },
    getRegion: function (countryId, regionId) {
      const country = this.getCountry(countryId);
      if (!country) {
        return '';
      }

      return find(country.regions, item => {
        return item.id === regionId;
      });
    }
  }
};
</script>

<style>

</style>
