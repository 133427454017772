<template>
  <div class="">
    <h2 class="c-title c-title--page">{{$t('planning.schedule.order')}}</h2>
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2 u-margin-bottom-medium" v-for="day of sessionDates" :key="getDateStr(day.date)">
        <h4 class="c-planning__session-day-title u-margin-bottom-small">
          {{ getDateStr(day.date) }}
        </h4>
        <div class="c-input" v-for="session of day.sessions" :key="session.id">
          <input v-model="selection"  :id="'session-' + session.id" type="checkbox"
                 class="c-input__field c-input__field-checkbox" :value="session.id" />
          <label :for="'session-' + session.id" class="c-input__label c-input__label-checkbox">
            {{session.name}}
          </label>
        </div>
      </div>
    </div>
    <div class="o-layout">
      <div class="o-layout__item">
        <sceRouteButton :route="{ name: 'admin.event.discipline.sessions'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="execute()" label="createOrder.execute" />
      </div>
    </div>
  </div>
</template>

<script>

import map from "lodash/map"
import find from "lodash/find"
import sortBy from "lodash/sortBy"

import moment from 'moment'

import { createBlockOrder } from '@/lib/sortathletes/orderCalc'
import { createMixedOrder } from '@/lib/sortathletes/mixedOrder'
import planningLib from 'client/lib/planning'
import sessionLib from '@/lib/session'

export default {
  name: 'createOrder',
  data: () => {
    return {
      sessionDates: [],
      selection: [],
    };
  },
  computed: {
    event: function () {
      return this.$store.state.events.current
    },
    eventDiscipline: function () {
      return find(
        this.$store.state.eventDisciplines.items,
        item => item.id === this.$route.params.disciplineId
      );
    },
    sessions: function () {
      return sortBy(this.$store.state.sessions.items, 'index');
    },
  },
  created: function() {
    this.selection = this.sessions.map(s => s.id)
    this.initSessionData()
  },
  methods: {
    initSessionData: function() {
      const days = planningLib.getEventDays(this.event);
      const sessionDates = map(days, (day) => {
        const sessions = planningLib.getSessionsByDate(day, this.sessions);

        return {
          date: day,
          sessions: sessions,
        };
      });
      this.sessionDates = sessionDates;
    },
    getDateStr: function (date) {
      return moment(date).format("dddd DD-MM-YYYY");
    },
    execute: function() {
      this.$notify.msgAsync(
        new Promise((resolve) => {
          this.scheduleParticipants()
          resolve()
        }),
        this.$t('planning.createOrders.processing'),
        this.$t('planning.createOrders.success'),
        this.$t('planning.createOrders.failed')
      )
    },
    createSessionOrder: async function(session) {
      const sessionType = sessionLib.getSessionRotationTypeFull(session)
      const sets = Array.from(Array(sessionType === 'mixed' || session.rotationFree ? 1 : session.sets), (_, i) => i+1)
      const config = this.eventDiscipline.planningConfig
      if (config.checkOverlaps) {
        await this.$store.dispatch('clubSchedule.get', this.eventDiscipline.eventId)
      }

      return Promise.all(sets.map(set => {
        return new Promise((resolve) => {
          if (sessionType === 'mixed')  {
            createMixedOrder(this.eventDiscipline.planningConfig, session).then(result => {
              const blocks = result.map(b => this.prepareMixedBlock(b))
              this.$store.dispatch('blocks.saveOrders', {prepBlocks: blocks, sessionId: session.id});
              resolve()
            })
          } else {
            createBlockOrder(this.eventDiscipline.planningConfig, session, set).then(result => {
              const blocks = result.blocks.map(b => this.prepareBlock(b))
              this.$store.dispatch('blocks.saveOrders', {prepBlocks: blocks, sessionId: session.id});
              resolve()
            })
          }
        })
      }))
    },
    scheduleParticipants: function() {
      const sessions = this.sessions.filter(s => this.selection.includes(s.id))
      return Promise.all(sessions.map(session => this.createSessionOrder(session)))
    },
    prepareBlock: function(block) {
      const parts = block.participations.map(part => ({
        part,
      }))

      return {
        block: block.block,
        parts
      }
    },
    prepareMixedBlock: function(block) {
      const parts = block.exercises.map(exercise => ({
        part: exercise.part,
        exerciseTypeId: exercise.exerciseTypeId,
      }))

      return {
        block: block.block,
        parts
      }
    },
  }
}
</script>

<style scoped>

</style>
