<template>
  <router-view ></router-view>
</template>

<script>
  import store from 'client/store';

  import find from 'lodash/find';

  export default {
    name:'session-wrapper',
    beforeRouteUpdate: function(to, from, next) {
      if (this.oldSessionId === to.params.sessionId) {
        next();
        return;
      }
      Promise.all([
        store.dispatch("panels.getBySession", { sessionId: to.params.sessionId })
      ]).then(() => {
        this.oldSessionId = to.params.sessionId;
        next();
      });
    },
    data: function() {
      return {
        oldSessionId: undefined
      }
    },
    computed: {
      session: function() {
        return find(store.state.sessions.items, item => {
          return item.id === this.$route.params.sessionId;
        });
      }
    },
    methods: {
      switchStatus: function(status) {
        store.dispatch('session.setStatus', { sessionId: this.session.id, newStatus: status});
      }
    }
  };
</script>

<style scoped>
</style>
