<template>
  <grid :config="{searchFields: ['name']}" :data="groups">
    <template v-slot:gridAdd>
      <sceRouteButton :route="{ name: 'admin.event.clubs.groups.add'}" label="group.add"
                      class="c-button--secondary c-button--large" icon="svg-symbols.svg#plus-icon"/>
    </template>
    <template v-slot:gridHeader>
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-4-of-5-at-medium">
          {{$t('name')}}
        </div>
        <div class="o-layout__item u-1-of-5-at-medium">
          {{$t('members.count')}}
        </div>
      </div>
    </template>
    <template v-slot:gridData="{element}">
      <div class="o-layout__item u-4-of-5-at-medium c-list__main-cell">
        <router-link class="c-list__link"
                     :to="{ name: 'admin.event.clubs.groups.view', params: { groupId: element.id}}">
          {{element.name}}
        </router-link>
      </div>
      <div class="o-layout__item u-1-of-5-at-medium">
        {{element.members.length}}
      </div>
    </template>
  </grid>
</template>

<script>
  import filter from 'lodash/filter'
  import sortBy from 'lodash/sortBy'

  export default {
    name:'groups-list',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    computed: {
      groups: function() {
        return sortBy(filter(this.$store.state.groups.items, item => item.clubId === this.$route.params.clubId), 'name')
      }
    },
  }
</script>

<style>
</style>
