<template>
  <div class="c-main--flex-1 c-scores">
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <div class="c-input">
          <label class="c-input__label" for="inputSession">{{$t('session')}}</label>
          <select class="c-input__field c-input__field-select" v-model="selectedSessionId"
                  id="inputSession" name="inputSession" tabindex="1">
            <option v-for="session in sessions" :value="session.id" :key="session.id">
              {{sessionLabel(session)}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large" v-if="selectedSessionId">
        <div class="c-input">
          <label class="c-input__label" for="inputSet">{{$t('set')}}</label>
          <select class="c-input__field c-input__field-select"
                  v-model="selectedSet" id="inputSet" name="inputSet"  tabindex="2">
            <option :value="0">
              {{$t('all')}}
            </option>
            <option v-for="set in selectedSession().sets" :value="set" :key="set">
              {{set}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large" v-if="selectedSessionId">
        <div class="c-input">
          <label class="c-input__label" for="inputSet">{{$t('exercise')}}</label>
          <select class="c-input__field c-input__field-select"
                  v-model="selectedExercise" id="inputExercise" name="inputExercise"  tabindex="3">
            <option v-for="exerciseType in exerciseTypes" :value="exerciseType.id" :key="exerciseType.id">
              {{$t('exercise.type.' + exerciseType.id)}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large" v-if="selectedExercise">
        <div class="c-input">
          <label class="c-input__label" for="inputSet">{{$t('bib')}}</label>
          <input class="c-input__field" v-model="selectedBib"
                 @blur="initBib()" tabindex="4" type="number" min="1" max="9999" step="1" ref="inputBib" />
        </div>
      </div>
      <div class="o-layout__item u-1-of-1">
        <div>
          <p>{{ message }}</p>
        </div>
        <template v-if="selectedPartId">
          <div class="block-part">
            <component :is="'participant-'+ participation.participantType" class="c-exercise__participant-name"
                       :participation="participation"/>
            <div class="c-exercise__cat">
              <span class="c-exercise__cat-label">{{$t('cat')}}</span>
              {{category.name}}
            </div>

            <div v-if="exercise" class="c-exercise__cat">
              {{$t('exercise.type.' + exercise.exerciseTypeId)}} {{activePass}}
            </div>
          </div>
          <exerciseEdit v-if="exerciseId && exercise.id === exerciseId" mode="admin" @close="externalClose()" />
        </template>
        <template v-if="! selectedPartId">
          {{$t('scoreinput.idle')}}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import find from 'lodash/find';
  import sortBy from 'lodash/sortBy';

  import exerciseLib from 'client/lib/exercise.js';
  import rounds from 'client/lib/rounds'
  import sessionLib from 'client/lib/session.js';

  export default {
    name: 'scoreinput',
    components: {
      'exerciseEdit': require('client/components/models/exercise-edit.vue').default,
      'participant-clubMember': require('client/components/models/participantClubMember.vue').default,
      'participant-group': require('client/components/models/participantGroup.vue').default,
    },
    data: function() {
      return {
        selectedSessionId: null,
        participations: [],
        selectedSet: 0,
        selectedBib: null,
        selectedPartId: null,
        exerciseTypes: [],
        selectedExercise: null,
        exerciseId: null,
        participation: null,
        category: null,
        dummy: '',
        message: '',
      }
    },
    computed: {
      sessions: function() {
        return sortBy(this.$store.state.sessions.items, 'index');
      },
      categories: function() {
        return this.$store.state.categories.items;
      },
      exercise: function() {
        return this.$store.state.exercise.exercise;
      },
      activePass: function () {
        return this.$store.state.exercise.pass;
      },
    },
    watch: {
      selectedSessionId: function() {
        // TODO check for open score

        this.initSession();
      },
      selectedSet: function() {
        // TODO check for open score

        this.initSession();
      },
      selectedExercise: function() {
        this.selectedPartId = null;
      },
      selectedPartId: function() {
        this.initPart();
      },
    },
    methods: {
      initSession: function() {
        if (this.selectedSessionId) {
          this.$store.dispatch('panels.getBySession', {
            sessionId: this.selectedSessionId,
          });
          const session = this.selectedSession();
          this.participations = sortBy(sessionLib.getParticipations(session, this.selectedSet), 'bib');
          this.exerciseTypes = this.$store.getters.sessionExerciseTypes(session);
        } else {
          this.participations = []
          this.selectedSet = 0
          this.exerciseTypes = []
        }
        this.selectedPartId = null
      },
      initBib: function() {
        if (! this.selectedBib) {
          this.selectedPartId = null
          return
        }

        // find participation
        const bib = parseInt(this.selectedBib)
        const participation = find(this.participations, i => i.bib === bib)

        if (participation) {
          this.selectedPartId = participation.id
        } else {
          this.selectedPartId = null
          this.message = this.$t('scoreinput.wrongBib')
        }
      },
      initPart: function() {
        // check if exercise is open and if it needs to be saved
        if (this.exerciseId && this.exerciseId === this.exercise.id) {
          this.$modal.show({
            title: this.$t('scoreinput.modal.title'),
            message: this.$t('scoreinput.modal.message'),
            onConfirm: () => { this.finishExercise(); },
            onCancel: () => { this.cancelExercise(); }
          });
        }

        if (this.selectedPartId) {
          this.message = ''
          this.participation = find(this.$store.state.participations.items, i => i.id === this.selectedPartId)
          this.category = find(this.$store.state.categories.items, i => i.id === this.participation.categoryId)
          const partRound = this.participation.rounds.find(r => r.roundIndex === this.selectedSession().roundIndex)
          if (! partRound ) {
            const round = rounds.getRound(this.selectedSession().roundIndex)
            this.message = this.$t('scoreinput.notSelected', { round: round.name})
          }
          else if (partRound.status !== 'present') {
            this.message = this.$t('scoreinput.notPresent', { status: partRound.status})
          }
          else {
            // select exercise
            const exercise = exerciseLib.getExercise(this.selectedPartId, this.selectedSession().roundIndex, this.selectedExercise)
            if (exercise) {
              this.message = ''
              this.$store.dispatch('exercise.load', {exerciseId: exercise.id, pass: 1}).then(() => {
                this.exerciseId = exercise.id
              })
            } else {
              this.message = this.$t('scoreinput.noExercise')
            }
          }
        } else {
          this.participation = null
          this.category = null
          if (this.selectedExercise) {
            this.$refs.inputBib.focus()
          }
        }
      },
      selectedSession: function() {
        return find(this.sessions, i => i.id === this.selectedSessionId)
      },
      externalClose: function() {
        this.exerciseId = null;
        this.selectedPartId = null;
      },
      cancelExercise: function() {
        if (this.exerciseId === null) {
          return;
        }

        this.$store.dispatch('exercise.cancel', {nopanel: true}).then(() => {
          this.exerciseId = null;
          this.selectedPartId = null;
        });
      },
      finishExercise: function() {
        console.log('finish exercise');
        if (this.exerciseId === null) {
          return;
        }

        this.$store.dispatch('exercise.finishPass', {nopanel: true}).then(() => {
          this.exerciseId = null;
          this.selectedPartId = null;
        });
      },
      sessionLabel: function(session) {
        return sessionLib.getSessionLabel(session);
      },
    }
  }
</script>

<style scoped>

</style>
