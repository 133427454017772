<template>
  <div class="c-main--flex-1" ref="focusbody">
    <h2 class="c-title c-title--page">{{$t('results')}}</h2>

    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <div class="c-input">
          <label class="c-input__label" for="selectedSession">{{$t('session')}}</label>
          <select class="c-input__field c-input__field-select" v-model="selectedSession"
                  id="selectedSession" name="selectedSession" tabindex="1">
            <option :value="undefined">
              {{$t('results.allSessions')}}
            </option>
            <option v-for="session in sessions" :value="session.id" :key="session.id">
              {{sessionLabel(session)}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <div class="c-input">
          <label class="c-input__label" for="selectedCategory">{{$t('category')}}</label>
          <select class="c-input__field c-input__field-select"
                  v-model="selectedCategory" id="selectedCategory" name="selectedCategory"  tabindex="2">
            <option v-for="category in selectedCategories" :value="category.id" :key="category.id">
              {{category.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <div class="c-input">
          <label class="c-input__label" for="selectedRanking">{{$t('ranking')}}</label>
          <select class="c-input__field c-input__field-select"
                  v-model="selectedRanking" id="selectedRanking" name="selectedRanking"  tabindex="3">
            <option v-for="ranking in selectedRankings" :value="ranking.id" :key="ranking.id">
              {{rankingName(ranking)}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="o-layout" v-if="selectedRanking">
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <div class="c-input">
          <label class="c-input__label" for="subDivision">{{$t('subDivision')}}</label>
          <select class="c-input__field c-input__field-select"
                  v-model.number="subDivision" id="subDivision" tabindex="4">
            <option :value="0">
              {{$t('all')}}
            </option>
            <option v-for="sub in getSubDivisions()" :value="sub+1" :key="sub">
              {{$t('subDivision') + ' ' + (sub+1)}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large" v-if="discipline.rotationType === 'rotation'">
        <div class="c-input">
          <label class="c-input__label" for="set">{{$t('set')}}</label>
          <select class="c-input__field c-input__field-select"
                  v-model.number="set" id="set" tabindex="5">
            <option :value="0">
              {{$t('all')}}
            </option>
            <option :value="1">
              {{$t('set')}} 1
            </option>
            <option :value="2">
              {{$t('set')}} 2
            </option>
            <option :value="3">
              {{$t('set')}} 3
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <div class="c-input">
          <label class="c-input__label" for="club">{{$t('club')}}</label>
          <select class="c-input__field c-input__field-select"
                  v-model="clubId" id="club" tabindex="6">
            <option :value="undefined">
              {{$t('all')}}
            </option>
            <option v-for="club in clubs" :value="club.id" :key="club.id">
              {{club.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
        <input v-model="awards" id="awards" type="checkbox" class="c-input__field c-input__field-checkbox" />
        <label for="awards" class="c-input__label c-input__label-checkbox">{{$t('awards')}}</label>
      </div>
      <div class="o-layout__item u-text-right">
        <div class="c-button c-button--primary c-button--large" v-on:click="print()">
          {{$t('export.pdf')}}
        </div>
        <button v-if="! hasMultiples()" class="c-button c-button--primary c-button--large"
                v-on:click="printCertificates(false)">
          {{$t('export.certificates')}}
        </button>
        <dropDown v-else  menuKey="results" :label="$t('export.certificates')" large primary>
          <template slot="content">
            <button class="c-button c-dropdown__button c-button--secondary" tag="button"
                    v-on:click="printCertificates(false)">>
              {{$t("export.certificates.single")}}
            </button>
            <button class="c-button c-dropdown__button c-button--secondary" tag="button"
                    v-on:click="printCertificates(true)">>
              {{$t("export.certificates.multiple")}}
            </button>
          </template>
        </dropDown>
        <div class="c-button c-button--primary c-button--large" v-on:click="export_csv()">
          {{$t('export.csv')}}
        </div>
        <div class="c-button c-button--primary c-button--large" v-on:click="export_csv(true)">
          {{$t('export.scores')}}
        </div>
      </div>
    </div>
    <ranking v-if="selectedRanking" :key="selectedRanking" :ranking="getRanking()" :filter="getRankingFilter()" />
  </div>
</template>

<script>
  import filter from 'lodash/filter'
  import find from 'lodash/find'
  import includes from 'lodash/includes'
  import map from 'lodash/map'
  import range from 'lodash/range'
  import sortBy from 'lodash/sortBy'

  import rankingLib from 'client/lib/ranking.js'
  import report from 'client/lib/report.js'
  import rounds from 'client/lib/rounds'
  import sessionLib from 'client/lib/session'

  export default {
    name: 'results',
    components: {
      dropDown: require('client/components/dropDown.vue').default,
      ranking: require('client/components/results/ranking.vue').default,
    },
    data: function() {
      return {
        selectedSession: undefined,
        selectedCategories: [],
        selectedCategory: undefined,
        selectedRankings: [],
        selectedRanking: undefined,
        subDivision: 0,
        set: 0,
        clubId: undefined,
        awards: false,
        categoryRound: undefined,
        round: undefined,
      }
    },
    computed: {
      sessions: function() {
        return sortBy(this.$store.state.sessions.items, 'index');
      },
      categories: function() {
        return sortBy(this.$store.state.categories.items, 'index');
      },
      rankings: function() {
        return this.$store.state.rankings.items;
      },
      clubs: function () {
        return sortBy(this.$store.state.clubs.items, 'name');
      },
      eventDiscipline: function () {
        return find(this.$store.state.eventDisciplines.items, (item) => {
          return item.id === this.$route.params.disciplineId
        });
      },
      discipline: function () {
        return this.$store.state.eventDiscipline.discipline;
      },
    },
    mounted: function() {
      this.setSelectedCategories();
    },
    watch: {
      selectedSession: function() {
        this.setSelectedCategories();
      },
      selectedCategory: function() {
        this.setRankings();
      },
      selectedRanking: function() {
        this.setRanking();
      }
    },
    methods: {
      setSelectedCategories: function() {
        if (this.selectedSession) {
          const session = find(this.sessions, item => item.id === this.selectedSession);
          const categories = map(session.categories, item => item.categoryId);
          this.selectedCategories = filter(this.categories, item => includes(categories, item.id));
        }
        else {
          this.selectedCategories = this.categories;
        }
        if (this.selectedCategories.length) {
          this.selectedCategory = this.selectedCategories[0].id;
        }
      },
      setRankings: function() {
        let rankings = [];
        if (this.selectedCategory) {
          rankings = rankingLib.sortRankings(filter(this.rankings, ranking => {
            return ranking.categoryId === this.selectedCategory;
          }));
        }
        this.selectedRankings = rankings;
      },
      setRanking: function() {
        if (this.selectedRanking) {
          const ranking = this.getRanking()
          const category = this.getCategory()

          this.categoryRound = find(category.rounds, i => i.roundIndex === ranking.roundIndex)
          this.round = rounds.getRound(ranking.roundIndex)
        } else {
          this.categoryRound = null
          this.round = null
        }
      },
      getSubDivisions: function () {
        if (this.categoryRound) {
          return range(this.categoryRound.subDivisions)
        }
        return []
      },
      getCategory: function() {
        return find(this.categories, category => category.id === this.selectedCategory)
      },
      getRanking: function() {
        if (this.selectedRanking) {
          return find(this.rankings, ranking => ranking.id === this.selectedRanking)
        }
        return null
      },
      getRankingFilter: function() {
        return {
          set: this.set,
          subDivision: this.subDivision,
          clubId: this.clubId,
          awards: this.awards ? 'awards' : false,
        }
      },
      rankingName: function(ranking) {
        const round = rounds.getRound(ranking.roundIndex)
        const category = find(this.$store.state.categories.items, item => { return item.id === ranking.categoryId })
        return rankingLib.getTitle(ranking, category, round)
      },
      sessionLabel: function(session) {
        return sessionLib.getSessionLabel(session)
      },
      hasMultiples: function() {
        const category = this.getCategory()
        const ranking = this.getRanking()

        return category?.participantType !== 'clubMember'
          || ranking.rankingType === 'TEAM-AA' || ranking.rankingType === 'TEAM-EVENTS'
      },
      print: function () {
        const section = rankingLib.preparePrint(
          this.getRanking(), this.getCategory(), this.round, this.discipline, this.getRankingFilter())
        report.publishReport(this.eventDiscipline, [section], 'results')
      },
      printCertificates: function(multiple) {
        const section = rankingLib.prepareCertificates(
          this.getRanking(), this.getCategory(), this.round, this.discipline, this.getRankingFilter(), multiple)

        report.publishCertificates(this.eventDiscipline, [section], 'awards')
      },
      export_csv: function (judgeScores = false) {
        let {fields, data} = rankingLib.prepareExport(this.getRanking(), this.getCategory(),
          this.round, this.discipline, this.getRankingFilter(), judgeScores)
        fields = rankingLib.flattenExportFields(fields)

        report.publishCSV2(data, fields, 'results')
      },
    }
  }
</script>

<style>
</style>
