<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{ $t('participation.edit') }}</h3>
    <participant :id="$route.params.participationId" :participantType="participantType"
                 @submit="update($event)" @remove="remove($event)" @cancel="cancel()" />
  </div>
</template>

<script>
import filter from 'lodash/filter';

export default {
  name: 'participation-view',
  components: {
    participant: require('./participation.vue').default,
  },
  data: function () {
    return {
      participantType: 'clubMember'
    }
  },
  created: function () {
    const participation = filter(this.$store.state.participations.items, item => {
      return item.id === this.$route.params.participationId;
    });
    this.participantType = participation.participantType;
  },
  methods: {
    update: function (data) {
      this.$notify.saveAsync(
        this.$t('participation'),
        this.$store.dispatch('participation.save', {data}),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.participants'});
        }
      );
    },
    remove: function (id) {
      this.$notify.removeAsync(
        this.$t('participation'),
        this.$store.dispatch('participation.remove', {data: {id: id}}),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.participants'});
        }
      );
    },
    cancel: function() {
      this.$navigation.goto({name: 'admin.event.discipline.participants'})
    },
  }
};
</script>

<style>
</style>
