<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{ $t('group.edit') }}</h3>

    <group :id="$route.params.groupId" @submit="update($event)" @remove="remove($event)"></group>
  </div>
</template>

<script>
export default {
  name: 'group-view',
  components: {
    group: require('./group.vue').default,
  },
  methods: {
    update: function (group) {
      this.$notify.saveAsync(
        this.$t('group'),
        this.$store.dispatch('group.save', {groupId: group.id, data: group}),
        () => {
          this.$navigation.goto({name: 'admin.event.clubs.groups'});
        }
      );
    },
    remove: function (group) {
      this.$notify.removeAsync(
        this.$t('group'),
        this.$store.dispatch('group.remove', {groupId: group.id}),
        () => {
          this.$navigation.goto({name: 'admin.event.clubs.groups'});
        }
      );
    },
  },
};
</script>

<style>
</style>
