<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('category.edit') }}</h2>
    <category :id="$route.params.categoryId" @submit="update($event)" @remove="remove($event)"/>
  </div>
</template>

<script>
export default {
  name: 'category-view',
  components: {
    category: require('./category.vue').default,
  },
  methods: {
    update: function (data) {
      data.eventDisciplineId = this.$route.params.disciplineId;
      this.$notify.saveAsync(
        this.$t('category'),
        this.$store.dispatch('category.save', data),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.categories'});
        }
      );
    },
    remove: function (category) {
      this.$notify.removeAsync(
        this.$t('category'),
        this.$store.dispatch('category.remove', category),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.categories'});
        }
      );
    },
  },
};
</script>

<style>
</style>
