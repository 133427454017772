<template>
  <club :id="$route.params.clubId" @submit="update($event)" @remove="remove($event)"/>
</template>

<script>
export default {
  name: 'club-view',
  components: {
    club: require('./club.vue').default,
  },
  methods: {
    update: function (club) {
      this.$notify.saveAsync(
        this.$t('club'),
        this.$store.dispatch('club.save', {clubId: club.id, data: club}),
        () => {
          this.$navigation.goto({name: 'admin.event.clubs'});
        }
      );
    },
    remove: function (club) {
      this.$notify.removeAsync(
        this.$t('club'),
        this.$store.dispatch('club.remove', {clubId: club.id}),
        () => {
          this.$navigation.goto({name: 'admin.event.clubs'});
        }
      );
    }
  }
};
</script>

<style>
</style>
