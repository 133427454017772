<template>
  <router-view></router-view>
</template>

<script>
  import find from 'lodash/find';

  export default {
    name: "event-wrapper",
    computed: {
      event: function () {
        return find(require('client/store').state.events.items, (item) => {
          return item.id === this.$route.params.eventId;
        });
      }
    }
  };
</script>
