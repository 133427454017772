<template>
  <grid :config="{searchFields: ['lastName','firstname']}" :data="members">
    <template slot="gridAdd">
      <sceRouteButton :route="{ name: 'admin.event.clubs.members.add'}" label="member.add"
                      class="c-button--secondary c-button--large" icon="svg-symbols.svg#plus-icon"/>
    </template>
    <template slot="gridHeader">
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-2-of-6-at-medium">
          {{ $t('lastname') }}
        </div>
        <div class="o-layout__item u-2-of-6-at-medium">
          {{ $t('firstname') }}
        </div>
        <div class="o-layout__item u-1-of-6-at-medium">
          {{ $t('dob') }}
        </div>
        <div class="o-layout__item u-1-of-6-at-medium">
          {{ $t('gender') }}
        </div>
      </div>
    </template>
    <template slot="gridData" slot-scope="slotProps">
      <div class="o-layout__item u-1-of-2 u-2-of-6-at-medium c-list__main-cell">
        <router-link class="c-list__link"
                     :to="{ name: 'admin.event.clubs.members.view', params: { memberId: slotProps.element.id}}">
          {{ slotProps.element.lastName }}
        </router-link>
      </div>
      <div class="o-layout__item u-1-of-2 u-2-of-6-at-medium c-list__main-cell">
        <router-link class="c-list__link"
                     :to="{ name: 'admin.event.clubs.members.view', params: { memberId: slotProps.element.id}}">
          {{ slotProps.element.firstname }}
        </router-link>
      </div>
      <div class="o-layout__item u-1-of-2 u-1-of-6-at-medium">
        {{ formatDob(slotProps.element.dob) }}
      </div>
      <div class="o-layout__item u-1-of-2 u-1-of-6-at-medium">
        {{ $t('gender.' + slotProps.element.gender) }}
      </div>
    </template>
  </grid>
</template>

<script>
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

const moment = require('moment');

export default {
  name:'members-list',
  components: {
    grid: require('client/components/dataGrid.vue').default,
  },
  computed: {
    members: function() {
      return sortBy(filter(this.$store.state.members.items, item => {
        return item.clubId === this.$route.params.clubId;
      }), ['lastName', 'firstname']);
    }
  },
  methods: {
    formatDob: function(date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY');
      }
      return '-';
    },
  },
};
</script>

<style>
</style>
