<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('display')}}</h2>

    <div class="c-tabs c-main">
      <div class="c-tab__tab-bar">
        <router-link v-for="tab in $options.tabs" :key="tab.name"
                     class="c-button c-button--large c-button--ghost c-tabs__tab-link" :to="{ name: tab.name}" :exact="tab.exact">
          {{$t(tab.label)}}
        </router-link>
      </div>
      <div class="c-tab__panel c-main u-margin-top-medium">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		name: "admin-display-wrapper",
    tabs: [{
      name: 'admin.event.display.list',
      label: 'displays',
      exact: true,
    },{
      name: 'admin.event.display.images.list',
      label: 'images',
      exact: false,
    }],
	};
</script>

<style scoped>
</style>
