<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-small">
      <h2 class="c-title c-title--page">{{ session.name}}: {{ getPanelTitle() }}</h2>

      <div class="c-list__header">
        <div class="o-layout c-list__row">
          <div class="o-layout__item u-2-of-8">
            &nbsp;
          </div>
          <div class="o-layout__item u-3-of-8">
            {{ $t('judge') }}
          </div>
          <div class="o-layout__item u-3-of-8">
            {{ $t('club') }}
          </div>
        </div>
      </div>
      <div class="c-list__body">
        <div class="o-layout c-list__row" v-for="position of positions" :key="position.position">
          <div class="o-layout__item u-2-of-8">
            {{ position.name }}
          </div>
          <div class="o-layout__item u-3-of-8">
            <sceSelect v-model="position.judgeId" :options="judges" :config="{emptyLabel: 'none', localized: false}"
                       :name="'judge:' + position.position" />
          </div>
          <div class="o-layout__item u-3-of-8">
            <sceSelect v-model="position.clubId" :options="clubs" :config="{emptyLabel: 'none', localized: false}"
                       :disabled="! hasClubField(position)" v-validate="{required: hasClubField(position)}"
                       :name="'club-' + position.position"
                       :class="{'is-invalid': errors.has('club-' + position.position)}"/>
          </div>
        </div>
      </div>
    </form>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.event.discipline.sessions.panels'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>
</template>

<script>

const sortBy = require('lodash/sortBy')

import memberLib from 'client/lib/member.js'
import panelLib from 'client/lib/panelHandler'

export default {
  name: 'sessionPanel',
  data: function() {
    return {
      positions: [],
    }
  },
  computed: {
    panel: function() {
      return this.$store.state.panels.items.find(p => p.id === this.$route.params.panelId)
    },
    session: function() {
      return this.$store.state.sessions.items.find(s => s.id === this.panel.sessionId)
    },
    judges: function() {
      return sortBy(this.$store.state.members.items.filter(m => m.judge)
        .map(j => ({id: j.id, name: memberLib.getName(j)})), 'name')
    },
    clubs: function() {
      return sortBy(this.$store.state.clubs.items, 'name')
    }
  },
  created: function() {
    this.positions = panelLib.calculatePositions(this.panel).map(p => ({
      position: p.position,
      name: p.name,
      judgeId: p.member?.judgeId,
      clubId: p.member?.clubId,
    }))
  },
  methods: {
    hasClubField: function(position) {
      let result = true
      if (! position.judgeId) {
        result = false
      } else {
        this.positions.forEach(p => {
          if (p.position >= position.position) return false // exit forEach

          if (p.judgeId === position.judgeId) {
            result = false
            return false
          }
        })
      }

      if (! result) position.clubId = null

      return result
    },
    getPanelTitle: function() {
      let title = ''

      if (this.session.sets > 1) {
        title += this.$t('set') + this.panel.set + ' '
      }

      return title + panelLib.panelName(this.panel, 'full')
    },
    submit: function() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          const panelMembers = []

          this.positions.filter(p => p.judgeId).forEach(p => {
            let member = panelMembers.find(m => m.judgeId === p.judgeId)

            if (! member) {
              member = {
                clubId: p.clubId,
                judgeId: p.judgeId,
                positions: [],
                id: panelMembers.length,
              }
              panelMembers.push(member)
            }

            member.positions.push(p.position)
          })

          const data = {
            members: panelMembers
          }

          this.$notify.saveAsync(
            this.$t('panel'),
            this.$store.dispatch('panel.save', { panelId: this.panel.id, data}),
            () => {
              this.$navigation.goto({name: 'admin.event.discipline.sessions.panels'});
            }
          );
        } else {
          this.$notify.warning('Validation errors');
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
