<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('image.add')}}</h2>
    <displayImage @submit="save($event)"></displayImage>
  </div>
</template>

<script>
  export default {
    name: "admin-image-add",
    components: {
      displayImage: require('./image.vue').default,
    },
    methods: {
      save: function(image) {
        console.log('add image', image);
        this.$store.dispatch('displayImage.add', {
          data: image,
        }).then(() => {
          this.$navigation.goto({name: 'admin.event.display.images.list'});
        });
      }
    },
  };
</script>
