<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{$t('participation.add')}}</h3>
    <participant @submit="update($event)" @cancel="cancel()" participantType="clubMember" />
  </div>
</template>

<script>
	export default {
		name: "participant-add",
    components: {
      participant: require('./participation.vue').default,
    },
		methods:{
			update: function(data) {
        this.$notify.saveAsync(
            this.$t('participation'),
            this.$store.dispatch('participation.add', {data}),
            () => {
              this.$navigation.goto({name: 'admin.event.discipline.participants'})
            }
        )
			},
      cancel: function() {
        this.$navigation.goto({name: 'admin.event.discipline.participants'})
      },
		},
	}
</script>

<style>
</style>
