<template>
  <div class="c-main">
    <grid :config="{searchFields: ['name']}" :data="images">
      <template v-slot:gridAdd>
        <router-link :to="{ name: 'admin.event.display.images.add'}"
                     class="c-button c-button--secondary c-button--large c-button--icon">
          <svg role="img" class="c-button__icon">
            <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
          </svg>
          <span class="c-button__label">{{$t("image.add")}}</span>
        </router-link>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item u-1-of-3-at-small">
            {{$t('name')}}
          </div>
          <div class="o-layout__item u-2-of-3-at-small">
            {{$t('image')}}
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item u-1-of-3-at-small c-list__main-cell">
          <router-link class="c-list__link" :to="{ name: 'admin.event.display.images.view',
           params: {imageId: element.id}}">
            {{element.name}}
          </router-link>
        </div>
        <div class="o-layout__item u-2-of-3-at-small">
          <img :src="element.image" height="90px" />
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
  import sortBy from 'lodash/sortBy';

  export default {
    name: 'admin-images-list',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    computed: {
      images: function () {
        return sortBy(this.$store.state.displayImages.items, 'name');
      }
    },
    methods: {}
  };
</script>
