<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('category.add')}}</h2>
    <category @submit="update($event)"></category>
  </div>
</template>


<script>
	export default {
		name: "category-add",
    components: {
      category: require('./category.vue').default,
    },
		methods:{
			update: function(category) {
        category.eventDisciplineId = this.$route.params.disciplineId;
        this.$notify.saveAsync(
            this.$t('category'),
            this.$store.dispatch('category.add', {data: category}),
            (resp) => {
              this.$navigation.goto({name: 'admin.event.discipline.categories.view', params: {categoryId: resp.data.id}});
            }
        );
			},
		},
	};
</script>

<style>
</style>
