<template>
  <div class="c-breadcrumb">
    <router-link exact :to="{ name: 'home'}" class="c-breadcrumb__item c-breadcrumb__link">
      {{$t('home')}}
    </router-link>
    <template v-if="event">
      <template v-if="! eventDiscipline">
        <span class="c-breadcrumb__item">{{eventTitle()}}</span>
      </template>
      <template v-else>
        <router-link exact :to="{ name: 'admin.event.view'}" class="c-breadcrumb__item c-breadcrumb__link">
          {{eventTitle()}}
        </router-link>
        <span v-if="eventDiscipline" class="c-breadcrumb__item">{{eventDisciplineTitle()}}</span>
      </template>
    </template>
  </div>
</template>

<script>
  import find from 'lodash/find';
  import store from "@/store";

  export default {
    name: 'breadcrump',
    computed: {
      event: function () {
        return store.state.events.current
      },
      eventDiscipline: function() {
        return find(this.$store.state.eventDisciplines.items, (item) => {
          return item.id === this.$route.params.disciplineId
        });
      }
    },
    methods: {
      eventTitle: function() {
        if (! this.event) {
          return '';
        }
        return this.event.titleShort ? this.event.titleShort : this.event.title;
      },
      eventDisciplineTitle: function() {
        if (! this.eventDiscipline) {
          return '';
        }
        return this.eventDiscipline.shortName ? this.eventDiscipline.shortName : this.eventDiscipline.name;
      },
    },
  }
</script>
