<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
	export default {
		name: "sessionPanels-wrapper",
	};
</script>

<style scoped>
</style>
