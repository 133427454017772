<template>
  <div class="c-list">

    <div class="c-list__header c-list__header-dashboard">
      <div class="o-layout c-list__row">
        <div class="o-layout__item u-2-of-3-at-small">
          <h3 class="c-title--rotation" :class="'c-title--rotation-' + rotation.status">{{$t('rotation')}} {{rotation.index+1}} - {{$t('session.status.' + rotation.status)}}</h3>
        </div>
      </div>
    </div>
    <div class="c-list__body">
      <panel v-for="panel in panels" :session="session" :key="rotation + '|' + panel.id"
             :rotation="rotation" :panel="panel"/>
    </div>

    <div class="u-margin-top-small u-margin-bottom-large">
        <div class="c-button c-button--primary c-button--large"
              v-if="rotation.status==='planned'"
              v-on:click="switchRotationStatus('active')">
          {{$t('rotation.button.activate')}}
        </div>

        <div class="c-button c-button--primary c-button--large"
              v-if="rotation.status==='active'"
              v-on:click="switchRotationStatus('finished')">
          {{$t('rotation.button.finish')}}
        </div>

        <div class="c-button c-button--secondary c-button--large"
              v-if="rotation.status==='active'"
              v-on:click="switchRotationStatus('planned')">
          {{$t('rotation.button.deactivate')}}
        </div>

        <div class="c-button c-button--primary c-button--large"
              v-if="rotation.status==='finished'"
              v-on:click="switchRotationStatus('active')">
          {{$t('rotation.button.reactivate')}}
        </div>
    </div>
  </div>
</template>

<script>
  import store from 'client/store';

  import filter from 'lodash/filter';
  import sortBy from 'lodash/sortBy';

  export default {
    name:'rotation-dashboard',
    props: ['session', 'rotation'],
    components: {
      "panel": require('./panelDashboard.vue').default,
    },
    computed: {
      panels: function() {
        return sortBy(filter(store.state.panels.items, item => {
          return this.session.id === item.sessionId;
        }), ['set', 'index']);
      },
    },
    methods: {
      switchRotationStatus: function(status) {
        store.dispatch('session.setRotationStatus', {
          sessionId: this.session.id, index: this.rotation.index, newStatus: status,
        });
      }
    }
  };
</script>

<style scoped>
</style>
