<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{ $t('member.edit') }}</h3>
    <member :id="$route.params.memberId" @submit="update($event)" @remove="remove($event)"></member>
  </div>
</template>

<script>
export default {
  name: 'member-view',
  components: {
    member: require('./member.vue').default,
  },
  methods: {
    update: function (member) {
      this.$notify.saveAsync(
        this.$t('member'),
        this.$store.dispatch('member.save', {memberId: member.id, data: member}),
        () => {
          this.$navigation.goto({name: 'admin.event.clubs.members'});
        }
      );
    },
    remove: function (data) {
      const promise = new Promise((resolve, reject) => {
        const promises = []

        promises.push(this.$store.dispatch('member.remove', { memberId: data.member.id }))

        data.groups.forEach(group => {
          group.members = group.members.filter(m => m.memberId !== data.member.id)
          group.members.forEach((m, i) => m.index = i)
          promises.push(this.$store.dispatch('group.save', { data: group }))
        })
        data.teams.forEach(team => {
          team.members = team.members.filter(m => m.memberId !== data.member.id)
          team.members.forEach((m, i) => m.index = i)
          promises.push(this.$store.dispatch('team.save', { data: team }))
        })
        Promise.all(promises).then(resolve, reject)
      })

      this.$notify.removeAsync(
        this.$t('member'),
        promise,
        () => {
          this.$navigation.goto({name: 'admin.event.clubs.members'})
        }
      )
    }
  }
};
</script>

<style>
</style>
