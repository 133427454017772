<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{ $t('team.edit') }}</h3>

    <team :id="$route.params.teamId" @submit="update($event)" @remove="remove($event)"></team>
  </div>
</template>

<script>
export default {
  name: 'team-view',
  components: {
    team: require('./team.vue').default,
  },
  methods: {
    update: function (team) {
      this.$notify.saveAsync(
        this.$t('team'),
        this.$store.dispatch('team.save', {teamId: team.id, data: team}),
        () => {
          this.$navigation.goto({name: 'admin.event.clubs.teams'});
        }
      );
    },
    remove: function (team) {
      this.$notify.removeAsync(
        this.$t('team'),
        this.$store.dispatch('team.remove', {teamId: team.id}),
        () => {
          this.$navigation.goto({name: 'admin.event.clubs.teams'});
        }
      );
    }
  }
};
</script>

<style>
</style>
