<template>
  <div class="c-main">
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-large c-planning__session-day-head">
        <h2 class="c-title c-title--page">{{$t('planning')}}</h2>
      </div>
      <div class="o-layout__item u-1-of-2-at-large c-planning__session-day-head-meta">
        <div class="c-button-like">{{$t('view')}}</div>

        <sceButton v-on:click="sessionsComponent = 'list'" icon="svg-symbols.svg#list"
                   :class="{'c-button--secondary': sessionsComponent !== 'list', 'c-button--tertiary': sessionsComponent === 'list'}" />
        <sceButton v-on:click="sessionsComponent = 'planning'" icon="svg-symbols.svg#grid"
                   class="u-margin-right-large"
                   :class="{'c-button--secondary': sessionsComponent !== 'planning', 'c-button--tertiary': sessionsComponent === 'planning'}" />

        <sceRouteButton icon="svg-symbols.svg#settings" class="c-button--secondary "
                        :route="{ name: 'admin.event.discipline.sessions.planning.config' }" />
        <dropDown menuKey="dropSchedule" :label="$t('planning.schedule')">
          <template slot="content">
            <sceButton label="planning.schedule.prelimTimes" v-on:click="scheduleSessions(false)"
                       class="c-dropdown__button c-button--secondary" />
            <sceButton label="planning.schedule.actualTimes" v-on:click="scheduleSessions(true)"
                       class="c-dropdown__button c-button--secondary" />
            <sceRouteButton :route="{ name: 'admin.event.discipline.sessions.createSubdivisions'}"
                            label="planning.schedule.subDivisions"
                            class="c-dropdown__button c-button--secondary" />
            <sceRouteButton :route="{ name: 'admin.event.discipline.sessions.createOrder'}"
                            label="planning.schedule.order"
                            class="c-dropdown__button c-button--secondary" />
            <sceRouteButton :route="{ name: 'admin.event.discipline.sessions.checkOrder'}"
                            label="planning.schedule.checkOrder"
                            class="c-dropdown__button c-button--secondary" />
            <sceRouteButton :route="{ name: 'admin.event.discipline.sessions.bib'}"
                            label="planning.schedule.bibAssignment"
                            class="c-dropdown__button c-button--secondary" />
          </template>
        </dropDown>
        <dropDown menuKey="dropPrint" :label="$t('export')">
          <template slot="content">
            <sceButton label="planning.print.schedule" v-on:click="printPlanning(true)"
                       class="c-dropdown__button c-button--secondary" />
            <sceButton label="planning.print.scheduleTemp" v-on:click="printPlanning(false)"
                       class="c-dropdown__button c-button--secondary" />
            <sceButton label="planning.print.order" v-on:click="printPdf()"
                       class="c-dropdown__button c-button--secondary" />
          </template>
        </dropDown>
      </div>

      <component :is="sessionsComponent" :event="event" class="o-layout__item" />
    </div>
  </div>
</template>

<script>
  import planning from 'client/lib/planning';

  import find from 'lodash/find';
  import map from 'lodash/map';
  import sortBy from 'lodash/sortBy';

  export default {
    name: "planningWrapper",
    components: {
      dropDown: require('client/components/dropDown.vue').default,
      list: require('./list.vue').default,
      planning: require('./planning.vue').default,
    },
    mixedColumnSizes: {
      category: 160,
      exerciseType: 125,
      regions: 175,
      actions: 40,
      base: 645,
    },
    data: function() {
      return {
        sessionsComponent: 'list',
      }
    },
    computed: {
      event: function () {
        return this.$store.state.events.current
      },
      eventDiscipline: function () {
        return find(
          this.$store.state.eventDisciplines.items,
          item => item.id === this.$route.params.disciplineId
        );
      },
      sessions: function () {
        return sortBy(this.$store.state.sessions.items, 'index');
      },
      country: function () {
        return find(
          this.$store.state.events.countries,
          c => c.id === this.event.countryId
        );
      },
      config: function () {
        const rs = this.eventDiscipline.regions || [];
        return {
          regions: map(rs, rs => find(this.country.regions, r => r.id === rs))
        };
      },
    },
    methods: {
      scheduleSessions: function(actual) {
        planning.setSessionTimings(actual);
      },

      printPlanning: function(includeTimes) {
        planning.printPlanning(this.event, this.eventDiscipline, this.config, includeTimes)
      },

      printPdf() {
        planning.printStartingOrder(this.eventDiscipline, this.sessions)
      },
    }
  };
</script>

