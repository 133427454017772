<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('event.edit') }}</h2>
    <event :id="$route.params.eventId" @submit="update($event)" @remove="remove($event)">
    </event>
  </div>
</template>

<script>
import config from 'client/config';

export default {
  components: {
    event: require('./event.vue').default,
  },
  computed: {
    event: function () {
      return this.$store.state.events.current
    },
  },
  methods: {
    update: function (event) {
      this.$notify.saveAsync(
        this.$t('event'),
        this.$http.put(config.root + '/seUsers/' + this.event.owner_id + '/events/' + event.id, event)
      );
    },
    remove: function (id) {
      this.$notify.removeAsync(
        this.$t('event'),
        this.$http.delete(config.root + '/seUsers/' + this.event.owner_id + '/events/' + id),
        () => {
          this.$navigation.goto({name: 'home'})
        }
      );
    }
  },
};
</script>

<style>
</style>
