<template>
  <div class="session u-padding-bottom-large">
    <div class="o-layout">
      <div class="o-layout__item u-text-right">
        <div class="c-button c-button--primary c-button--large"
             v-if="session.status==='planned'" v-on:click="switchStatus('active')">
          {{$t('session.button.activate')}}
        </div>
        <div class="c-button c-button--primary c-button--large"
             v-if="session.status==='active'" v-on:click="switchStatus('finished')">
          {{$t('session.button.finish')}}
        </div>
        <div class="c-button c-button--secondary c-button--large"
             v-if="session.status==='active'" v-on:click="switchStatus('planned')">
          {{$t('session.button.deactivate')}}
        </div>
        <div class="c-button c-button--primary c-button--large"
             v-if="session.status==='finished'" v-on:click="switchStatus('active')">
          {{$t('session.button.reactivate')}}
        </div>
      </div>
    </div>
    <div class="rotations">
      <rotation v-for="rotation in session.rotationsSet" :key="rotation.index" :session="session" :rotation="rotation" />
    </div>

    <div class="c-tabs c-main">
      <div class="c-tab__tab-bar u-margin-bottom-medium">
        <a v-for="tab in tabs" :key="tab.name" v-on:click="setActiveTab(tab.name)"
           class="c-button c-button--large c-button--ghost c-tabs__tab-link"
           :class="{'is-active': activeTab === tab.name}">
          {{ tab.label }}
        </a>
      </div>
      <div v-if="activeTab === 'results'" class="c-tabs__panel c-main">
        <sessionResults :sessionId="session.id" :data="results"/>
      </div>
      <div v-if="activeTab === 'selections'" class="c-tabs__panel c-main">
        <sessionSelections :sessionId="session.id" :data="selections" />
      </div>
    </div>
  </div>
</template>

<script>
  import rounds from 'client/lib/rounds'
  import sessionLib from 'client/lib/session.js';

  import find from 'lodash/find';
  import map from 'lodash/map';

  export default {
    name:'session-dashboard',
    data: function() {
      return {
        results: {
          rankingTypes: [],
          rankingSelection: [],
        },
        selections: {
          types: [],
          categorySelection: [],
        },
        tabs: [],
        activeTab: 'results',
        setLabel: 'panel',
      }
    },
    components: {
      rotation: require('./rotationDashboard.vue').default,
      sessionResults: require('client/components/sessionResults.vue').default,
      sessionSelections: require('./sessionSelections.vue').default,
    },
    created: function() {
      this.setLabel = sessionLib.getSetLabel();
      this.init();
    },
    watch: {
      session: function() {
        this.init();
      }
    },
    computed: {
      session: function() {
        return find(this.$store.state.sessions.items, i => i.id === this.$route.params.sessionId)
      },
      sessions: function() {
        return this.$store.state.sessions.items
      },
      categories: function() {
        return this.$store.state.categories.items
      },
      rounds: function() {
        return rounds.getRounds()
      },
      round: function() {
        const session = find(this.$store.state.sessions.items, i => i.id === this.$route.params.sessionId)
        return rounds.getRound(session.roundIndex)
      },
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline
      },
      rotationType: function() {
        return sessionLib.getSessionRotationType(this.session.id)
      }
    },
    methods: {
      init: function() {
        const {rankingTypes, categories} = sessionLib.getRankingTypes(this.session)
        this.results.rankingTypes = rankingTypes

        this.results.rankingSelection =
          map(categories, category => sessionLib.getRankingSelections(rankingTypes, category))

        const selectionTypes = sessionLib.getSelectionTypes(this.session)
        this.selections.types = selectionTypes
        this.selections.categorySelection = categories.map(
          c => sessionLib.getCategorySelections(this.round, selectionTypes, c))

        const tabs = [
          {
            name: 'results',
            label: this.$t('results'),
          },
        ]
        if (selectionTypes.length) {
          tabs.push({
            name: 'selections',
            label: this.$t('selection'),
          })
        }
        this.tabs = tabs
      },
      setActiveTab: function(tab) {
        this.activeTab = tab
      },
      getCategory: function(categoryId) {
        return find(this.categories, item => item.id === categoryId)
      },
      switchStatus: function(status) {
        this.$store.dispatch('session.setStatus', { sessionId: this.session.id, newStatus: status})
      }
    }
  }
</script>

<style scoped>
</style>
