<template>
  <div class="c-main">
    <grid :config="{searchFields: ['name']}" :data="displays">
      <template v-slot:gridAdd>
        <router-link exact :to="{ name: 'admin.event.display.add'}"
                     class="c-button c-button--secondary c-button--large c-button--icon">
          <svg role="img" class="c-button__icon">
            <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
          </svg>
          <span class="c-button__label">{{$t("display.add")}}</span>
        </router-link>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item u-2-of-3-at-medium">
            {{$t('name')}}
          </div>
          <div class="o-layout__item u-1-of-3-at-medium">
            {{$t('active')}}
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item u-2-of-3-at-medium c-list__main-cell">
          <router-link class="c-list__link" :to="{ name: 'admin.event.display.view',
           params: {displayId: element.id}}">
            {{element.name}}
          </router-link>
        </div>
        <div class="o-layout__item u-1-of-3-at-medium">
          {{element.active}}
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
  export default {
    name: 'admin-display-list',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    computed: {
      displays: function () {
        return this.$store.state.displays.items
      }
    },
    methods: {}
  };
</script>

<style>

</style>
