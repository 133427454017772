<template>
  <div>
    <div :class="{'c-sidebar__active-group': group === 'events'}">
      <router-link
        class="c-sidebar__item c-sidebar__item-main"
        exact :to="{ name: 'home'}">
        {{$t('events')}}
      </router-link>
    </div>
    <div v-if="isAdmin" :class="{'c-sidebar__active-group': group === 'customers'}">
      <router-link
        class="c-sidebar__item c-sidebar__item-main"
        exact :to="{ name: 'admin.customers'}">
        {{$t('customers')}}
      </router-link>
    </div>
    <div :class="{'c-sidebar__active-group': group === 'user'}">
      <router-link
        exact class="c-sidebar__item c-sidebar__item-main c-sidebar__item-with-children"
        v-if="group !== 'user'"
        :to="{ name: 'admin.user.profile', params: { userId: user.id}}">
        {{$t('menu.user')}}
      </router-link>
      <template v-if="group === 'user'">
        <div class="c-sidebar__item c-sidebar__item-main c-sidebar__item-with-children c-sidebar__item-open">
          {{$t('menu.user')}}
        </div>
        <router-link exact class="c-sidebar__item" :to="{ name: 'admin.user.profile'}">
          {{$t('menu.userProfile')}}
        </router-link>
        <router-link exact class="c-sidebar__item" :to="{ name: 'admin.user.planningDefaults'}">
          {{$t('menu.planningDefaults')}}
        </router-link>
        <router-link exact class="c-sidebar__item" :to="{ name: 'admin.user.displayTemplates'}">
          {{$t('menu.displayTemplates')}}
        </router-link>
      </template>
    </div>
  </div>
</template>
<script>
  import userLib from 'client/lib/user';
  export default {
    name: 'home-menu',
    data: function() {
      return {
        isAdmin: false,
        group: 'events',
      };
    },
    computed: {
      user: function() {
        return this.$store.state.user.instance;
      },
    },
    watch: {
      $route: {
        immediate: true,
        handler: function(to) {
          const path = to.path;
          if (path.startsWith('/admin/user')) {
            this.group = 'user';
          } else if (path.startsWith('/admin/customers')) {
            this.group = 'customers';
          } else {
            this.group = 'events';
          }
        },
      },
    },
    created: function() {
      this.isAdmin = userLib.checkRole('admin');
    },
    methods: {
    },
  };
</script>
