<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('display.add')}}</h2>
    <display @submit="save($event)"></display>
  </div>
</template>


<script>
  export default {
    name: "display-add",
    components: {
      display: require('./display.vue').default,
    },
    methods: {
      save: function(display) {
        this.$notify.saveAsync(
            this.$t('display'),
            this.$store.dispatch('display.add', {
              display: display
            }),
            (resp) => {
              this.$navigation.goto({name: 'admin.event.display.view', params: {displayId: resp.id} });
            }
        );
      }
    },
  };
</script>

<style>
</style>
