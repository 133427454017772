<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1">
      <sceTextField v-model="name" name="clubname" label="name" v-validate data-vv-rules="required"
                    :class="{'is-invalid': errors.has('clubname')}" />
        <div class="o-layout">
          <div class="o-layout__item u-1-of-2-at-small">
            <sceSelect v-model="countryId" name="country" label="country"
                       :options="countries" :config="{optionLabel: 'id', emptyLabel: 'none'}" />
          </div>
          <div v-if="countryId && regions.length" class="o-layout__item u-1-of-2-at-small">
            <sceSelect v-model="regionId" name="region" label="region" :options="regions"
                       :config="{localized: false, emptyLabel: 'none'}" />
          </div>
        </div>

        <div class="o-layout">
          <div class="o-layout__item u-1-of-2-at-small">
            <sceTextField v-model="externalId" name="externalId" label="externalId" />
          </div>
        </div>

    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.event.clubs'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';

  export default {
    name: "club",
    props: ['id'],
    data: function () {
      return {
        name: '',
        externalId: undefined,
        countryId: undefined,
        regionId: undefined,
        regions: [],
      };
    },
    computed: {
      club: function () {
        if (this.id) {
          return filter(this.$store.state.clubs.items, (item) => {
            return item.id === this.id
          })[0];
        }
        return null;
      },
      countries: function() {
        return this.$store.state.events.countries;
      }
    },
    created: function () {
      this.setClub();
    },
    watch: {
      club: function () {
        this.setClub();
      },
      countryId: function() {
        this.setRegions();
      },
    },
    methods: {
      setClub: function () {
        if (this.club) {
          this.name = this.club.name;
          this.externalId= this.club.accessId;
          this.countryId = this.club.countryId;
          this.regionId = this.club.regionId;
        }
      },
      setRegions: function() {
        const countryId = this.countryId;
        if (countryId) {
          const country = find(this.countries, item => item.id === countryId);
          if (country && country.regions) {
            this.regions = country.regions;
          } else {
            this.regions = [];
          }
        } else {
          this.regions = [];
        }

        if (this.regionId) {
          const region = find(this.regions, item => item.id === this.regionId);
          if (! region) {
            this.regionId = null;
          }
        }
      },
      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            const data = {
              id: this.id,
              eventId: this.$route.params.eventId,
              name: this.name,
              accessId: this.externalId,
              countryId: this.countryId,
              regionId: this.regionId
            };

            this.$emit('submit', data);
          } else {
            this.$notify.warning('validation errors');
          }
        });
      },
      remove: function () {
        this.$modal.show({
          title: this.$t('delete.confirm.title'),
          message: this.$t('delete.confirm.text', {item: this.name}),
          onConfirm: () => {
            this.$emit('remove', this.club);
          }
        });
      }
    }
  };
</script>

<style>
</style>
