<template>
  <form action="#">
    <div class="o-layout">
      <div class="o-layout__item">
        <div class="c-input" :class="{'is-invalid': errors.has('name')}">
          <label class="c-input__label" for="name">{{$t('name')}}</label>
          <input v-model="name" class="c-input__field" name="name" type="text" id="name" v-validate
                 data-vv-rules="required">
        </div>
      </div>
      <div class="o-layout__item">
        <image-input v-model="file"/>
      </div>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <button v-if="id" class="c-button c-button--secondary c-button--large" v-on:click.prevent="remove()">
          {{$t('delete')}}
        </button>
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <router-link exact :to="{ name: 'admin.event.display.images.list'}">
          <button class="c-button c-button--ghost c-button--large">{{$t("cancel")}}</button>
        </router-link>
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()">
          {{$t('save')}}
        </button>
      </div>
    </footer>
  </form>
</template>

<script type="text/babel">
  import store from 'client/store';

  import find from 'lodash/find';

  export default {
    name: "displayImage",
    components: {
      'image-input': require('client/components/imageInput.vue').default,
    },
    data: function () {
      return {
        name: null,
        id: null,
        file: undefined,
      };
    },
    computed: {
      image: function () {
        const imageId = this.$route.params.imageId;
        if (imageId) {
          return find(store.state.displayImages.items, item => {
            return item.id === imageId;
          });
        }
        return null;
      },
    },
    created: function () {
      this.setImage();
    },
    methods: {
      setImage: function () {
        if (this.image) {
          this.id = this.image.id;
          this.name = this.image.name;
          this.file = this.image.image;
        }
      },
      submit: function () {
        this.$validator.validateAll();
        if (this.errors.any()) {
          console.log('errors');
        }
        if (!this.errors.any()) {
          this.$emit('submit', {
            id: this.id,
            name: this.name,
            image: this.file,
          });
        }
      },
      remove: function () {
        this.$emit('remove', this.id);
      }
    }
  };
</script>
