<template>
  <div class="c-main c-main--flex-1">
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-small">
        <div class="o-layout">
          <div class="o-layout__item">
            <h2 class="c-title c-title--page">{{ $t('dashboard') }}</h2>
          </div>
          <div class="o-layout__item u-1-of-2-at-large">
            <div class="c-input">
              <label class="c-input__label" for="round">{{ $t('round') }}</label>
              <select class="c-input__field c-input__field-select" v-model="roundIndex" id="round" name="round">
                <option v-for="round in rounds" :value="round.index" :key="round.index">
                  {{ round.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="o-layout__item u-1-of-2-at-large">
            <div class="c-input">
              <label class="c-input__label" for="date">{{ $t('date') }}</label>
              <select class="c-input__field c-input__field-select" v-model="date" id="date" name="date">
                <option v-for="listDate in dates" :value="listDate" :key="listDate">
                  {{ listDate.substr(0, 10) }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-small">
        <div class="c-card c-card--primary">
          <div class="o-layout">
            <div class="o-layout__item u-margin-bottom-small" v-for="d of timerDisplays" :key="d.id">
              <timer :display="d" :ts="ts" />
            </div>
            <div class="o-layout__item" style="align: right">
              <timer v-if="timerAdd" :ts="ts"  @close="timerAdd = false" />
              <sceButton v-else icon="svg-symbols.svg#plus" v-on:click="addTimer()" label="timer"
                         class="c-button__icon--primary c-button--primary c-button--small" />
            </div>
          </div>
        </div>
      </div>

      <div class="o-layout__item u-1-of-1">
        <div class="c-tabs u-margin-top-large">
          <div class="c-tabs__tab-bar">
            <template v-for="session in sessions">
              <div v-if="session.roundIndex === roundIndex && session.date.substring(0,10) === date"
                   :key="session.id"
                   class="c-button c-button--large c-button--ghost c-tabs__tab-link"
                   :class="{ 'is-active': session.id === selectedId}" v-on:click="select(session.id)">
                <span class="c-tabs__tab-link-title">{{ session.name }}</span>
                <span class="c-tabs__tab-link-subtitle">{{ $t('session.status.' + session.status) }}</span>
              </div>
            </template>
          </div>

          <div class="c-tab__panel c-main u-margin-top-medium">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import filter from "lodash/filter"
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import sortBy from 'lodash/sortBy'
const timesync = require('timesync')

import rounds from 'client/lib/rounds'

export default {
  name: 'discipline-dashboard',
  components: {
    timer: require('./timer.vue').default,
  },
  data: function () {
    return {
      dates: [],
      date: undefined,
      roundIndex: undefined,
      selectedId: undefined,
      ts: null,
      timerAdd: false,
    };
  },
  computed: {
    rounds: function () {
      return rounds.getRounds()
    },
    timerDisplays: function() {
      return sortBy(filter(this.$store.state.displays.items, d =>
        d.config.timer.active && d.timer?.status && includes(d.eventDisciplines, this.$route.params.disciplineId)
      ), 'name');
    },
    sessions: function() {
      return sortBy(
        this.$store.state.sessions.items.filter(
          s => s.roundIndex === this.roundIndex && s.date.substring(0, 10) === this.date),
        'index'
      )
    },
  },
  watch: {
    sessions: function() {
      const current = this.sessions.find(s => s.id === this.selectedId)
      if (! current) {
        const session = this.selectPreferredSession(this.sessions)
        if (session) {
          this.select(session.id)
        }
      }
    },
    roundIndex: {
      immediate: true,
      handler: function() {
        this.setSessionDates()
      },
    },
  },
  created: function () {
    console.log('init time sync');
    this.ts = timesync.create({
      server: '/timesync',
      interval: 300000,
    })
    this.initialRoundDateSelection()
  },
  beforeDestroy: function () {
    console.log('destroy timesync');
    this.ts.destroy();
  },
  methods: {
    setSessionDates: function () {
      const sessions = this.$store.state.sessions.items.filter(s => s.roundIndex === this.roundIndex)
      if (!sessions.length) {
        return
      }

      let dates = [];
      forEach(sessions, item => {
        const date = item.date.substring(0, 10);
        if (!includes(dates, date)) {
          dates.push(date);
        }
      })
      this.dates = dates.sort()
      const session = this.selectPreferredSession(sessions)
      this.date = session.date.substring(0, 10)
    },
    select: function (sessionId) {
      this.selectedId = sessionId
      this.$navigation.goto({name: 'admin.event.discipline.dashboard.session', params: {sessionId: sessionId}})
    },
    getSelectedSession: function () {
      return find(this.sessions, item => {
        return item.id === this.selectedId
      });
    },
    selectPreferredSession: function (sessions) {
      console.log('set preferred session')
      sessions = sortBy(sessions, 'index')
      let session = sessions.find(s => s.status === 'active')
      if (!session) {
        session = sessions.find(s => s.status === 'planned')
      }

      if (!session) {
        session = sessions[0]
      }

      return session
    },

    initialRoundDateSelection: function () {
      const sessions = sortBy(this.$store.state.sessions.items, 'index')
      if (!sessions.length) {
        return
      }

      const session = this.selectPreferredSession(sessions)

      this.roundIndex = session.roundIndex
    },
    addTimer: function() {
      this.timerAdd = true
    },
    editTimer: function(display) {
      this.timerEdit = {
        display,
        mode: 'edit',
        timer: cloneDeep(display.timer)
      }
    },
  }
}
</script>

<style scoped>
</style>
