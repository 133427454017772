<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">
      {{$t('session.participantSelection', {category: category.name, round: roundTarget.name})}}
    </h3>

    <div v-if="status === 'preview'" >
      <h4>{{ $t('selections.basedOnRanking', {ranking: rankingTitle})}}</h4>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-4-at-small">
          <sceSelect v-model="statusAll" name="status-all" :options="$options.statusOptions"/>
        </div>
        <div class="o-layout__item u-1-of-4-at-small">
          <sceButton label="updateAll" v-on:click="setAll()" class="c-button--primary" />
        </div>
        <div class="o-layout__item">
          <table class="o-table c-table--results u-margin-top-medium">
            <thead>
              <tr>
                <th class="c-table-cell__valign-bottom">{{$t('report.rank')}}</th>
                <th class="c-table-cell__valign-bottom">{{$t('bib')}}</th>
                <th class="c-table-cell__valign-bottom">{{$t('participant')}}</th>
                <th>
                  <div class="c-table-cell__valign-bottom">{{ $t('total') }}</div>
                </th>
                <th>
                  <div class="c-table-cell__valign-bottom">{{ $t('status.current') }}</div>
                </th>
                <th>
                  <div class="c-table-cell__valign-bottom">{{ $t('status.new') }}</div>
                </th>
              </tr>
            </thead>
            <tbody class="c-table__body">
              <tr v-for="(item, _i) in candidates" :key="item.id" class="js-row">
                <td class="c-table__selection-cells">
                  <div class="c-table__cell-content">
                    {{ item.ranking }}
                  </div>
                </td>
                <td class="c-table__selection-cells">
                  <div class="c-table__cell-content">
                    {{ item.part.bib }}
                  </div>
                </td>
                <td class="c-table__selection-cells">
                  <div class="c-table__cell-content">
                    <strong v-for="name in item.names" :key="name">{{ name }}<br/></strong>
                    {{ item.club }}
                  </div>
                </td>
                <td class="c-table__selection-cells">
                  <div class="c-table__cell-content">
                    {{ item.result }}
                  </div>
                </td>
                <td class="c-table__selection-cells">
                  <div class="c-table__cell-content">
                    {{ $t('participation.status.' + item.statusCurrent) }}
                  </div>
                </td>
                <td class="c-table__selection-cells">
                  <div class="c-table__cell-content">
                    <sceSelect v-model="item.statusNew" :name="'status-' + _i" :options="$options.statusOptions"
                               v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('status-'+ _i)}"/>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="o-layout__item">
          <sceButton label="saveClose" v-on:click="saveClose()" class="c-button--primary" />
          <sceButton label="saveOrder" v-on:click="saveOrder()" class="c-button--primary" />
        </div>
      </div>
    </div>
    <div v-if="status === 'orderPrep'">
      <h4>Schedule selected participants</h4>
      <div class="o-layout">
        <div class="o-layout__item u-3-of-8-at-small">
          <sceSelect v-model="targetSessionId" name="status-all" label="session"
                     :disabled="status !== 'orderPrep'" :options="targetSessions"/>
        </div>
        <div class="o-layout__item u-3-of-8-at-small">
          <sceSelect v-model="selectionOrder" name="selectionOrder" label="selectionOrder"
                     :disabled="status !== 'orderPrep'" :options="$options.selectionOrder"/>
        </div>
        <div class="o-layout__item u-1-of-8-at-small" v-if="rotationType !== 'mixed'">
          <sceSelect v-model="selectionSet" name="selectionSet" label="set"
                     :disabled="status !== 'orderPrep' || ! targetSessionId" :options="selectionSets"/>
        </div>
        <div v-if="! multiBlock" class="o-layout__item u-1-of-8-at-small">
          <div class="c-input">
            <label class="c-input__label" for="selectionRotation">
              {{$t('rotation')}}
            </label>
            <select class="c-input__field c-input__field-select" v-model="selectionRotation"
                    id="selectionRotation" name="selectionRotation" :disabled="status !== 'orderPrep' || ! targetSessionId">
              <option v-for="rotation in targetSessionRotations" :key="rotation" :value="rotation-1">
                {{rotation}}
              </option>
            </select>
          </div>
        </div>
        <div v-if="multiBlock" class="o-layout__item">
          <div class="o-layout">
            <div class="o-layout__item">
              <h5>Select blocks to use for the category</h5>
            </div>
            <div class="o-layout__item u-1-of-4-at-small" v-for="r of targetSessionRotations" :key="r" >
              <div class="c-input">
                <input v-model="selectionBlocks" :id="'block-'+r" type="checkbox"
                       class="c-input__field c-input__field-checkbox" :value="r" />
                <label :for="'block-'+r" class="c-input__label c-input__label-checkbox">
                  {{$t('block') + ' ' + r}}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="o-layout__item">
          <sceButton v-if="status === 'orderPrep'" label="proceed" v-on:click="orderProceed()" class="c-button--primary" />
        </div>
      </div>
    </div>
    <div v-if="message">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import filter from "lodash/filter"
import find from "lodash/find"
import reverse from "lodash/reverse"
import sortBy from "lodash/sortBy"

import panelHandler from '@/lib/panelHandler'
import participantLib from "@/lib/participant"
import rankingLib from 'client/lib/ranking'
import rounds from 'client/lib/rounds'
import sessionLib from 'client/lib/session'
import options from 'client/lib/options'
import form from "@/lib/form"
import { shuffleArray } from '@/lib/sortathletes/common'

export default {
  name: "selections",
  props: ['sessionId', 'categoryId', 'type', 'exerciseTypeId'],
  ...options.participation,
  selectionOrder: form.idNamePairs(['byRankingReverse', 'byRanking', 'random'], 'selectionOrder.'),
  data: function() {
    return {
      statusAll: 'present',
      status: 'void',
      ranking: null,
      message: null,
      candidates: [],
      targetSessions: [],
      targetSessionId: null,
      targetSession: null,
      targetSessionSets: [],
      targetSessionRotations: 0,
      rotationType: 'block',
      exerciseSelection: false,
      exerciseTypes: [],
      targetExerciseTypeId: this.exerciseTypeId,
      setsEnabled: false,
      selectionSets: [],
      selectionSet: 0,
      selectionRotation: 0,
      selectionBlocks: [],
      setLabel: 'panel',
      selectionOrder: 'byRankingReverse',
      multiBlock: false,
    }
  },
  computed: {
    session: function() {
      return find(this.$store.state.sessions.items, item => item.id === this.sessionId)
    },
    roundSource: function() {
      return rounds.getRound(this.session.roundIndex)
    },
    roundTarget: function() {
      const roundIndex = rounds.nextRound(this.roundSource, this.type)
      return rounds.getRound(roundIndex)
    },
    category: function() {
      return find(this.$store.state.categories.items, item => item.id === this.categoryId)
    },
  },
  created: function() {
    this.setLabel = sessionLib.getSetLabel()
    this.init()
  },
  watch: {
    targetSessionId: function() {
      if (this.targetSessionId) {
        this.targetSession = this.$store.state.sessions.items.find(s => s.id === this.targetSessionId)
        this.rotationType = sessionLib.getSessionRotationTypeFull(this.targetSession)
        this.targetExerciseTypeId = this.exerciseTypeId

        let sets = []
        this.exerciseSelection = false
        if (this.targetSession.rotationFree) {
          sets = Array.from(Array(this.targetSession.sets), (e, i) => ({ id: i+1, name: i+1}))
        }
        else {
          if (this.rotationType === 'mixed' && this.exerciseTypeId === 'all') {
            this.exerciseTypes = sessionLib.getSessionCategoryExerciseTypes(this.targetSession.id, this.categoryId)
            this.targetExerciseTypeId = this.exerciseTypes[0]
            this.exerciseSelection = true
            this.setsEnabled = false
          }
          else {
            const set = panelHandler.getSet(this.targetSession, this.categoryId, this.targetExerciseTypeId)
            sets = [{ id: set, name: set }]
          }
        }
        this.selectionSets = sets
        this.selectionSet = sets[0].id

        this.selectionRotation = 0
      }
      else {
        this.targetSession = null
        this.targetSessionRotations = 0
        this.exerciseSelection = null
        this.selectionSet = 0
        this.selectionRotation = 0
        this.selectionBlocks = []
      }
    },
    selectionSet: function() {
      this.targetSessionRotations = sessionLib.getSetRotations(this.targetSession, this.selectionSet)
      if (this.selectionRotation > this.targetSessionRotations) {
        this.selectionRotation = 1
      }
      this.selectionBlocks = this.selectionBlocks.filter(r => r <= this.targetSessionRotations)
    }
  },
  methods: {
    init: function () {
      // fetch ranking used as basis for selection
      let ranking = rankingLib.getRanking(
        this.categoryId, this.roundSource.id, this.type, 'open', null, this.exerciseTypeId)
      if (! ranking) {
        ranking = rankingLib.getRanking(
          this.categoryId, this.roundSource.id, this.type, 'regular', null, this.exerciseTypeId)
      }

      if (! ranking) {
        this.message = this.$t('selections.noRanking')
        return
      }

      this.message = null
      this.ranking = ranking

      // prepare selection preview
      this.rankingTitle = rankingLib.getTitle(ranking, this.category, this.roundSource)
      const addCountry = participantLib.getAddCountry(this.category)

      const candidates = []

      this.ranking.items.forEach(ri => {
        const part = participantLib.getParticipation(ri.participationId)
        const participant = participantLib.getParticipant(part)
        const partRound = part.rounds.find(r => r.roundIndex === this.roundTarget.index)
        let statusCurrent = 'not-selected'

        if (partRound) {
          if (rankingLib.hasEvent(this.ranking)) {
            const exerciseSelection = partRound.exerciseSelection.find(i => i.exerciseTypeId === this.exerciseTypeId)
            if (exerciseSelection?.options) {
              statusCurrent = exerciseSelection.options.status
            }
          }
          else {
            statusCurrent = partRound.status
          }
        }

        let names = []
        switch (part.participantType) {
          case 'team':
            names = [participant.name]
            break
          default:
            names = participantLib.getParticipantNames(part)
        }

        candidates.push({
          part: cloneDeep(part),
          names,
          club: participantLib.getClubName(part, participant, addCountry),
          ranking: ri.ranking,
          result: ri.result,
          tie: ri.tie,
          statusCurrent,
          statusNew: 'not-selected',
        })
      })

      // apply selection rules
      const catRoundTo = find(this.category.rounds, item => item.roundIndex === this.roundTarget.index)
      let qCount = catRoundTo.selectionCriteria.maxCount
      let maxCount = qCount + catRoundTo.selectionCriteria.reserveCount
      if (maxCount <= 0) {
        maxCount = 99999
        qCount = 99999
      }

      const minScore = catRoundTo.selectionCriteria.minScore
      const clubCount = catRoundTo.selectionCriteria.clubCount
      const clubs = []
      let selectedCount = 0
      let tie = false
      let selectedStatus = 'present'

      candidates.forEach(c => {
        if (! (tie && c.tie)) {
          if ((selectedCount >= maxCount || parseFloat(c.result) < minScore - 0.000001)) {
            return false
          }
          if (selectedCount >= qCount) {
            selectedStatus = 'reserve'
          }
        }

        if (tie && c.tie && selectedCount === qCount) {
          maxCount++
        }

        if (clubCount > 0) {
          const club = find(clubs, item => item.clubId ===c.club.id)
          if (club) {
            club.count += 1
            if (club.count > clubCount) {
              return
            }
          } else {
            clubs.push({
              clubId: c.part.clubId,
              count: 1,
            })
          }
        }

        c.statusNew = selectedStatus
        selectedCount++
        tie = c.tie
      })

      this.candidates = candidates
      this.status = 'preview'
    },
    setAll: function() {
      this.candidates.forEach(c => c.statusNew = this.statusAll)
    },
    saveStatus: function(callback) {
      const roundTargetId = this.roundTarget.id

      const promises = []
      this.candidates.forEach(c => {
        if (c.statusCurrent === c.statusNew) {
          return
        }

        let aaStatus = c.statusNew
        if (this.type === 'EVENTS' ||this.type === 'TEAM-EVENTS') {
          aaStatus = 'present'
        }

        let eventStatus = null
        if (this.type === 'EVENTS') {
          eventStatus = c.statusNew
        }

        promises.push(this.updateParticipation(c.part, aaStatus, eventStatus))

        if (this.type === 'TEAM-AA' && aaStatus === 'present') {
          // set team members to present if team is present
          const teamParts = participantLib.getTeamParticipations(c.part.id)
          teamParts.forEach(tp => {
            const tpStatus = participantLib.getStatus(tp, roundTargetId)
            if (tpStatus !== 'present') {
              const teamPart = cloneDeep(tp)
              promises.push(this.updateParticipation(teamPart, 'present'))
            }
          })
        }
      })

      this.$notify.saveAsync(
          this.$t('participations'),
          Promise.all(promises),
          callback,
      )
    },
    updateParticipation: function(part, newStatus, eventStatus = null) {
      let partRound = find(part.rounds, i => i.roundIndex === this.roundTarget.index)
      if (partRound) {
        partRound.status = newStatus
      } else {
        partRound = {
          roundIndex: this.roundTarget.index,
          id: this.roundTarget.index,
          status: newStatus,
          subDivision: 1,
        };
        part.rounds.push(partRound)
      }

      if (eventStatus) {
        if (! partRound.exerciseSelection) {
          partRound.exerciseSelection = []
        }
        const exerciseSelection = find(
          partRound.exerciseSelection, item => item.exerciseTypeId === this.exerciseTypeId)
        if (! exerciseSelection) {
          partRound.exerciseSelection.push({
            exerciseTypeId: this.exerciseTypeId,
            options: {
              status: eventStatus
            },
          })
        } else {
          exerciseSelection.options.status = eventStatus
        }
      }

      const data = {
        id: part.id,
        rounds: part.rounds,
      }
      return this.$store.dispatch('participation.save', {data})
    },
    saveClose: function() {
      this.saveStatus(() => {
        this.$navigation.goto({name: 'admin.event.discipline.dashboard.session', params: {sessionId: this.sessionId}})
      })
    },
    saveOrder: function() {
      this.saveStatus(() => {
        // prepare data for finals starting order
        this.targetSessions = sortBy(filter(this.$store.state.sessions.items, session => {
          const sessionCategory = find(session.categories, i => i.categoryId === this.categoryId)
          if (! sessionCategory || session.roundIndex !== this.roundTarget.index) return false

          if (rankingLib.hasEvent(this.ranking)) {
            const exerciseTypes = sessionLib.getSessionCategoryExerciseTypes(session.id, this.categoryId)
            if (! exerciseTypes.includes(this.exerciseTypeId)) return false
          }
          return true
        }), 'index')

        if (this.targetSessions.length) {
          this.targetSessionId = this.targetSessions[0].id
          this.selectionSet = 1
          this.$nextTick(() => {
            console.log('set multiBlock', this.rotationType, this.exerciseTypeId)
            this.multiBlock = (this.rotationType === 'rotation' && this.exerciseTypeId === 'all')
            this.status = 'orderPrep'
          })
        }
        else {
          this.targetSessionId = null
        }
      })
    },
    orderProceed: function() {
      // get selected participants in order
      let candidates = this.candidates.filter(c => c.statusNew === 'present')
      switch (this.selectionOrder) {
        case 'byRankingReverse':
          candidates = reverse(candidates)
          break
        case 'random':
          candidates = shuffleArray(candidates)
          break
      }

      let blocks  = this.$store.state.blocks.items.filter(b =>
        b.sessionId === this.targetSessionId && b.set === this.selectionSet)
      if (this.multiBlock) {
        blocks = sortBy(blocks.filter(b => this.selectionBlocks.includes(b.index + 1)), 'index')
      }
      else {
        blocks = blocks.filter(b => b.index === this.selectionRotation)
      }

      let orders = []
      const quotum = Math.ceil(candidates.length / blocks.length)
      blocks.forEach((block, i) => {
        orders.push({
          block,
          candidates: candidates.slice(i * quotum, (i * quotum) + quotum)
        })
      })

      console.log(candidates, blocks, quotum, orders)

      // convert candidates to participants
      orders.forEach(o => {
        let parts = []
        o.candidates.forEach(c => {
          switch (c.part.participantType) {
            case 'team': {
              const memberParts = participantLib.getTeamParticipations(c.part.id)
              parts = parts.concat(memberParts)
              break
            }
            default:
              parts.push(c.part)
          }
        })
        o.parts = parts
      })

      const exerciseTypeId = this.rotationType === 'mixed' ? this.targetExerciseTypeId : null
      const data = orders.map(o => {
        const blockParticipations = this.$store.state.blockParticipations.items.filter(bp => {
          if (bp.blockId !== o.block.id) return false

          const part = o.parts.find(p => p.id === bp.participationId)
          if (! part) return false

          return exerciseTypeId === null || bp.exerciseTypeId !== exerciseTypeId
        })
        const parts = blockParticipations.map(bp => ({
          part: { id : bp.participationId },
          exerciseTypeId: bp.exerciseTypeId,
        }))

        o.parts.forEach(part => {
          parts.push({
            part,
            exerciseTypeId,
          })
        })

        return {
          block: o.block,
          parts,
        }
      })

      console.log(data)

      this.$store.dispatch('blocks.saveOrders', {prepBlocks: data, sessionId: this.targetSessionId, append: true}).then(
        () => {
          this.message = this.$t('selections.orderSuccess')
          this.status = 'order'
        },
        err => {
          this.message = this.$t('selections.orderError') + ': ' + err
          this.status = 'order'
        }
      )

    },
  },
}
</script>

<style scoped>

</style>
