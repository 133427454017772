<template>
  <keep-alive include="teams-list">
    <router-view :key="$route.fullPath"></router-view>
  </keep-alive>
</template>

<script>
  export default {
    name: "team-wrapper",
  };
</script>

<style>
</style>
