<template>
  <div class="">
    <h3 class="c-title c-title--page-section">{{ $t('participation.edit') }}</h3>
    <participant :id="$route.params.participationId" participantType="team"
                 @submit="update($event)" @remove="remove($event)" @cancel="cancel()"/>
  </div>
</template>

<script>
export default {
  name: 'team-participation-view',
  components: {
    participant: require('./participation.vue').default,
  },
  methods: {
    update: function (data) {
      this.$notify.saveAsync(
        this.$t('participation'),
        this.$store.dispatch('participation.save', {data: data}),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.participantsTeam'});
        }
      );
    },
    remove: function (id) {
      this.$notify.removeAsync(
        this.$t('participation'),
        this.$store.dispatch('participation.remove', {data: {id: id}}),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.participantsTeam'});
        }
      );
    },
    cancel: function() {
      this.$navigation.goto({name: 'admin.event.discipline.participantsTeam'})
    },
  },
};
</script>

<style>
</style>
